import React, { FC } from 'react';
import consts from 'src/config/consts';
import styled from 'styled-components';

const Image = styled.img<{ blockIcon: boolean; isCarousel?: boolean }>`
  display: inline-block;
  //Prevent img stretching on Safari browser
  height: ${({ isCarousel }) => isCarousel && '100%'};
  width: ${({ isCarousel }) => isCarousel && 'auto'};

  width: ${({ blockIcon }) => blockIcon && '37px'};

  td & {
    width: ${({ blockIcon }) => blockIcon && '100% !important'};
  }

  td & {
    padding-right: ${({ theme }) => theme.space.xs};

    &:not(:only-child) {
      max-width: 49%;

      &:first-of-type {
        padding-right: unset;
      }
    }
  }

  @media not all and (min-resolution: 0.001dpcm) {
    @supports (-webkit-appearance: none) and (stroke-color: transparent) {
      image-rendering: unset;
    }
  }
`;

type ImageProps = {
  imageId: string;
  node: Elvita.Topic;
  isCarousel?: boolean;
};

const Img: FC<ImageProps> = ({ node, imageId, isCarousel }) => {
  return (
    <>
      <Image
        className={node.attribs.class}
        width={node.attribs.width && node.attribs.width}
        src={`${consts.storageUrl}images/${imageId}`}
        alt={node.attribs.alt}
        blockIcon={
          node.attribs['data-template'] &&
          node.attribs['data-template'] === '68'
        }
        isCarousel={isCarousel}
      />
    </>
  );
};

export default Img;
