import * as React from 'react';
import styled from 'styled-components';
import Portal from '../Portal';
import { EndGradient, StartGradient } from '../topic/Table';

const Overlay = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  flex-direction: column;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(112, 112, 112, 0.8);
  z-index: 999;
`;

const ModalWindow = styled.div<{ width?: string; isPortrait: boolean }>`
  width: 85%;
  max-width: 85%;
  padding: 36.5px 12.5px 16.5px 16.5px;
  background-color: #ffffff;
  box-shadow: 1px 2px 8px 2px rgba(0, 0, 0, 0.25);
  border-radius: ${({ theme }) => theme.radii.s};
  height: ${({ isPortrait }) => (isPortrait ? '60%' : '90%')};
  position: relative;

  @media (min-width: ${({ theme }) => theme.screens.l}) {
    width: ${({ width }) => (width ? width : '40%')};
    max-width: ${({ width }) => (width ? width : '40%')};
  }
`;

const CloseIcon = styled.img`
  cursor: pointer;
  position: absolute;
  top: 16.5px;
  right: 12.5px;
`;

const RotateIcon = styled.img`
  margin-bottom: ${({ theme }) => theme.space.sm};
`;

interface Props {
  isVisible: boolean;
  width?: string;
  scrollStart?: boolean;
  scrollEnd?: boolean;
  isTableModal?: boolean;
  onClose: () => void;
  handleLoad?(isLoading, value?: number): void;
}

const Modal: React.FC<Props> = ({
  children,
  isVisible,
  width,
  scrollStart,
  scrollEnd,
  isTableModal,
  onClose,
  handleLoad,
}) => {
  const [isPortrait, setIsPortrait] = React.useState(false);
  const ref = React.useRef<HTMLDivElement | null>(null);

  React.useEffect(() => {
    if (handleLoad) {
      handleLoad(true);
    }
  }, []);

  React.useEffect(() => {
    const handleResize = () => {
      setIsPortrait(window.innerWidth < 500);
    };

    window.addEventListener('resize', handleResize);
  }, []);

  React.useEffect(() => {
    if (handleLoad) {
      setTimeout(() => {
        ref?.current && handleLoad(false, ref.current.offsetWidth);
      }, 300);
    }
  }, [handleLoad, ref]);

  if (!isVisible) {
    return null;
  }

  return (
    <Portal id="#modal">
      <Overlay onClick={onClose}>
        {isPortrait && isTableModal && <RotateIcon src="/images/rotate.svg" />}
        <ModalWindow
          width={width}
          onClick={e => e.stopPropagation()}
          ref={ref}
          onLoad={() =>
            handleLoad && handleLoad(false, ref.current.offsetWidth)
          }
          isPortrait={isPortrait}
        >
          <CloseIcon src="/images/close.svg" onClick={onClose} />
          {isTableModal && <StartGradient hide={scrollStart} inModal />}
          {children}
          {isTableModal && <EndGradient hide={scrollEnd} inModal />}
        </ModalWindow>
      </Overlay>
    </Portal>
  );
};

export default Modal;
