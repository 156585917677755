export function isProcedureCarousel(node: Elvita.Topic, data: string) {
  return (
    node.parent.attribs.class === 'task' &&
    node.attribs.class.includes('steps') &&
    data.indexOf('<div class="step-info">') > 0
  );
}

export function getProcedureCarouselData(
  node: Elvita.Topic,
  data: string,
): { data: Elvita.StepData[] | []; id: string } {
  if (!isProcedureCarousel(node, data)) {
    return { data: [], id: '' };
  }

  const items: Elvita.StepData[] = [];

  node.children
    .filter(child => child.attribs?.class.includes('step'))
    .map(step => {
      let stepData: Elvita.StepData = {};

      const info = step?.children.find(c =>
        c.attribs?.class.includes('step-info'),
      );

      if (info && info?.children) {
        const figure = info?.children.find(c => {
          return c?.attribs?.class && c?.attribs?.class.includes('figure');
        });

        if (figure && figure.children) {
          const img = figure.children.find(i => i?.attribs?.src !== undefined);

          stepData = { id: img.attribs.src, node: img };
        }
      }

      const sub = data.substring(data.indexOf(step.attribs.id));
      const stepAction = sub.indexOf('<div class="step-action">');
      const stepInfo = sub.indexOf('<div class="step-info">');

      if (stepAction > 0 && stepInfo > 0) {
        stepData.data = sub.substring(stepAction, stepInfo);

        items.push(stepData);
      }
    });

  if (items.length == 0 || !items.every(item => item.id && item.node)) {
    return { data: [], id: '' };
  }
  return { data: items, id: items[0].id };
}

export function getMedialistCarouselData(node: Elvita.Topic): {
  items: Elvita.MedialistCarouselData[] | [];
  id: string;
} {
  const items: Elvita.MedialistCarouselData[] = [];

  const divs = node.children.filter(c => c.attribs?.class.includes('mli'));
  if (divs.length > 0) {
    divs.map(item => {
      const img = item?.children.find(c => c.name === 'img');

      items.push({ id: img.attribs.src, node: img });
    });

    return { items, id: divs[0].attribs.id };
  }

  return { items: [], id: '' };
}
