import * as React from 'react';
import { useFetchTopic } from '../hooks/useFetchData';
import Loader from './Loader';
import StringToComponent from './StringToComponent';
import styled from 'styled-components';
import Feedback from './Feedback';
import { Redirect } from 'react-router';

const Wrapper = styled.div`
  @media (min-width: ${({ theme }) => theme.screens.md}) {
    width: 488px;
    align-self: center;
  }
  @media (min-width: ${({ theme }) => theme.screens.l}) {
    order: 2;
    width: 46.94%;
    align-self: flex-start;
  }
`;

type TopicContent = {
  topicId: string;
};

const TopicContent: React.FC<TopicContent> = ({ topicId }) => {
  const { isLoading, isError, data } = useFetchTopic(topicId);

  if (!data || isError) {
    return null;
  }

  if (data?.status === 404) {
    return <Redirect to="/404" />;
  }

  return (
    <Wrapper>
      {isLoading ? <Loader /> : <StringToComponent data={data?.data} />}
      <Feedback />
    </Wrapper>
  );
};

export default TopicContent;
