import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { QueryClientProvider, QueryClient } from 'react-query';
import { MatomoProvider, createInstance } from '@datapunt/matomo-tracker-react';

const queryClient = new QueryClient({
  defaultOptions: { queries: { staleTime: 1000 * 60, retry: false } },
});


const matomoInstance = createInstance({ 
	siteId: parseInt(process.env.REACT_APP_MATOMO_SITEID),
	urlBase: process.env.REACT_APP_MATOMO_BASEURL
})

ReactDOM.render(
  <React.StrictMode>
	<MatomoProvider value={matomoInstance}>
		<QueryClientProvider client={queryClient}>
		<App />
		</QueryClientProvider>
	</MatomoProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
