import React, { FC } from 'react';
import styled from 'styled-components';

const StyledLink = styled.a`
  font-size: 15px;
  line-height: ${({ theme }) => theme.lineHeights.xxs};
  color: ${({ theme }) => theme.colors.elvitaWebPurple};
  font-weight: 300;

  &:hover {
    text-decoration: underline;
  }

  &:active {
    color: ${({ theme }) => theme.colors.elvitaWebPink};
    text-decoration: none;
  }
`;

type AnchorProps = {
  href: string;
  children: React.ReactNode;
  data: string;
};

const ExternalAnchor: FC<AnchorProps> = ({ href, data }) => {
  return <StyledLink href={href}>{data}</StyledLink>;
};

export default ExternalAnchor;
