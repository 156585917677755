import { useLanguageStore } from 'src/context/LanguageStore';
import { useQuery } from 'react-query';
import { useProductConfig } from './useFetchData';
import consts from 'src/config/consts';
import axios from 'axios';

const searchApi = axios.create({
  baseURL: consts.searchUrl,
  headers: {
    'Content-Type': 'application/json',
  },
});

const queryContent = async ({
  manifestId,
  lang,
  term,
}: {
  manifestId: string;
  lang: string;
  term: string;
}) => {
  const filter = `manifestId eq '${manifestId}' and languageCode eq '${lang}'`;
  const res = await searchApi.post(
    `/${consts.implementation}/${consts.contentVersion}/content`,
    {
      search: `${term}*`,
      filter: filter,
    },
  );
  return res.data;
};

const queryProducts = async ({
  lang,
  term,
}: {
  lang: string;
  term: string;
}) => {
  const filter = `languageCode eq '${lang}'`;
  const res = await searchApi.post(
    `/${consts.implementation}/${consts.contentVersion}/product`,
    {
      search: `/.*${term}.*/`,
      filter: filter,
      queryType: 'full',
    },
  );
  return res.data;
};

export default function useSearch(term: string, type: Elvita.SearchType) {
  const lang = useLanguageStore(state => state.lang);
  const { data: productConfig } = useProductConfig();

  return useQuery(
    [
      type === 'product' ? 'productSearch' : 'search',
      term,
      lang,
      productConfig?.ManifestId,
    ],
    type === 'product'
      ? () => queryProducts({ lang, term }) as Promise<Elvita.SearchResults>
      : () =>
          queryContent({
            lang,
            term,
            manifestId: productConfig?.ManifestId as string,
          }) as Promise<Elvita.SearchResults>,
    {
      enabled:
        term.length > 2 && (!!productConfig || type === 'product') && !!lang,
      select(data) {
        return data.value;
      },
    },
  );
}
