import React from 'react';
import { useParams } from 'react-router-dom';
import { useFetchStartPage } from './useFetchData';

export function useMetaTitle(title?: string): void {
  const { productId } = useParams<{ productId: string }>();
  const { error, data } = useFetchStartPage();

  React.useEffect(() => {
    if (title) {
      document.title = title;
    } else if (productId) {
      if (!error && data) {
        if (data.data.title && data.data.description) {
          document.title = `${data.data.title} ${data.data.description}`;
        }
      }
    }
  }, [data, title, error, productId]);
}
