import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const AnchorLink = styled.a`
  color: ${p => p.theme.colors.elvitaWebPurple};
  &:active {
    color: ${p => p.theme.colors.elvitaWebPink};
  }
  &:hover {
    text-decoration: underline;
  }
`;
