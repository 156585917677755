import * as React from 'react';
import styled, { keyframes } from 'styled-components';

const spin = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }`;

const Spinner = styled.div`
  text-indent: -9999em;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background: linear-gradient(
    to right,
    #fff 10%,
    ${({ theme }) => theme.colors.elvitaWebPink} 42%
  );
  position: relative;
  animation: ${spin} 1.4s infinite linear;
  transform: translateZ(0);

  &:before {
    width: 50%;
    height: 50%;
    background: #ffffff;
    border-radius: 100% 0 0 0;
    position: absolute;
    top: 0;
    left: 0;
    content: '';
  }

  &:after {
    background: #fff;
    width: 75%;
    height: 75%;
    border-radius: 50%;
    content: '';
    margin: auto;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }
`;

type LoderProps = {
  styles?: React.CSSProperties;
};

const Loader: React.FC<LoderProps> = ({ styles }) => (
  <>
    <Spinner style={styles} />
  </>
);

export default Loader;
