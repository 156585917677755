import styled from 'styled-components';

export default styled.p`
  font-size: ${({ theme }) => theme.fontSizes.s};
  line-height: 17px;
  color: ${({ theme }) => theme.colors.elvitaTrueBlack};
  margin-bottom: ${({ theme }) => theme.space.s};
  font-weight: 300;

  img {
    height: 17px;

    td & {
      height: auto;
    }
  }

  li & {
    margin-bottom: unset;
  }

  td & {
    font-size: ${({ theme }) => theme.fontSizes.xxs};
    line-height: 15.6px;

    &:only-of-type {
      margin-bottom: unset;
    }
  }

  .attention & {
    max-width: unset;

    li & {
      display: inline;
    }

    &:last-of-type {
      margin-bottom: unset;
    }
  }
`;
