import styled, { css } from 'styled-components';
import React from 'react';

const GuiStyle = css`
  font-weight: 700;
  color: ${({ theme }) => theme.colors.elvitaGray};
  font-family: ${({ theme }) => theme.fonts.light};
  letter-spacing: 0.04rem;

  @media print {
    line-height: initial;
  }
`;

const StyledSpan = styled.span<{
  gui?: boolean;
}>`
  font-family: ${({ theme }) => theme.fonts.light};
  line-height: 0.14;

  ${({ gui }) => gui && GuiStyle};
`;

type SpanProps = {
  className: string;
};

const Span: React.FC<SpanProps> = ({ children, className }) => {
  if (!className) {
    return <StyledSpan>{children}</StyledSpan>;
  }

  return (
    <StyledSpan gui={className.indexOf('gui') > -1} className={className}>
      {children}
    </StyledSpan>
  );
};
export default Span;
