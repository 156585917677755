import styled from 'styled-components';

export const PageContentRow = styled.div<{ center?: boolean }>`
  display: flex;
  width: 100%;
  position: relative;
  justify-content: ${({ center }) => center && 'center'};
  flex-direction: column;

  @media (min-width: ${({ theme }) => theme.screens.l}) {
    flex-direction: row;
  }
`;
