import * as React from 'react';
import HotspotImage from './HotspotImage';
import styled from 'styled-components';
import { SwiperSlide } from 'swiper/react';
import Carousel from '../Carousel';

const ImageWrapper = styled.div`
  display: inline-block;
  height: 100%;
  width: auto;
  vertical-align: middle;
  max-width: 100%;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 -14px;

  .swiper-slide {
    height: 100%;
  }

  .swiper-slide-next,
  .swiper-slide-prev {
    position: relative;

    img {
      height: 100%;
    }
  }

  .swiper-slide-next {
    img {
      position: absolute;
      left: -30px;

      @media (min-width: ${({ theme }) => theme.screens.sm}) {
        left: -55px;
      }
    }
  }

  .swiper-slide-prev {
    img {
      position: absolute;
      right: -30px;

      @media (min-width: ${({ theme }) => theme.screens.sm}) {
        right: -55px;
      }
    }
  }

  @media (min-width: ${({ theme }) => theme.screens.md}) {
    margin: unset;
  }
`;

type HotspotProps = {
  hotspot: Elvita.Hotspot;
};

const Hotspot: React.FC<HotspotProps> = ({ hotspot }) => {
  if (!hotspot) {
    return null;
  }

  return (
    <>
      {hotspot.imageMaps.length > 1 ? (
        <Carousel
          itemsLength={hotspot?.imageMaps.length}
          isStartPage
          className="swiper"
        >
          {hotspot.imageMaps.map((image, index) => (
            <SwiperSlide
              style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
              }}
              key={index}
            >
              <div>
                <HotspotImage image={image} key={index} />
              </div>
            </SwiperSlide>
          ))}
        </Carousel>
      ) : (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <HotspotImage image={hotspot.imageMaps[0]} />
        </div>
      )}
    </>
  );
};

export default Hotspot;
