import styled from 'styled-components';

export const MainContent = styled.div`
  width: 100%;
  align-self: center;

  @media (min-width: ${({ theme }) => theme.screens.md}) {
    width: 488px;
  }

  @media (min-width: ${({ theme }) => theme.screens.l}) {
    width: 46.94%;
  }
`;
