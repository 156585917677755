import React, { FC } from 'react';
import styled, { css, keyframes } from 'styled-components';
import Modal from '../modal/Modal';

const show = keyframes`
  0% { opacity: 0; }
  100% { opacity: 1; }
`;

const Wrapper = styled.div`
  position: relative;
`;

const GridTableStyle = css`
  tr,
  th,
  td {
    border-width: ${({ theme }) => theme.borderWidths.s}px;
    border-color: ${({ theme }) => theme.colors.elvitaMidGray};
    border-style: solid;
    padding: ${({ theme }) => theme.space.table};
    font-size: ${({ theme }) => theme.fontSizes.xxs};
    line-height: 15.6px;
    padding: ${({ theme }) => theme.space.table};
  }

  tr:not(:last-of-type) td {
    border-color: ${({ theme }) => theme.colors.elvitaMidGray};
  }
`;

const TableWrapper = styled.table<{
  grid: boolean;
  standard: boolean;
  width?: number;
}>`
  width: ${({ width }) => (width ? `${width}px` : '100%')};
  border-spacing: 0;
  border-collapse: collapse;
  margin-bottom: ${({ theme }) => theme.space.l};
  text-align: left;

  caption {
    font-style: italic;
    color: ${({ theme }) => theme.colors.elvitaMediumGrey};
    text-align: left;
    font-size: ${({ theme }) => theme.fontSizes.m};
    line-height: ${({ theme }) => theme.lineHeights.s};
  }

  td {
    vertical-align: top;
  }

  th {
    font-weight: 700;
    font-size: ${({ theme }) => theme.fontSizes.xxs};
    padding: ${({ theme }) => theme.space.table};
    line-height: 15.6px;
    background-color: ${({ theme }) => theme.colors.elvitaLighterGray};
  }

  ${({ grid }) => grid && GridTableStyle};

  @media (min-width: ${({ theme }) => theme.screens.l}) {
    width: 100%;
  }
`;

const Content = styled.div`
  overflow: auto;
  height: 100%;
  width: 90%;
  animation: ${show} 0.5s;
  position: relative;
`;

const ExpanderIcon = styled.img`
  position: absolute;
  right: -4px;
  top: -2px;
  cursor: pointer;
  z-index: 999;

  @media (min-width: ${({ theme }) => theme.screens.l}) {
    display: none;
  }
`;

export const StartGradient = styled.div<{ hide: boolean; inModal?: boolean }>`
  width: 12px;
  height: 100%;
  position: absolute;
  left: ${({ inModal }) => (inModal ? '12.5px' : '0')};
  top: 0;
  z-index: 99;
  opacity: ${({ hide }) => (hide ? 0 : 1)};
  transition: opacity 0.2s;

  background: linear-gradient(90deg, #ffffff, #ffffff00);
`;

export const EndGradient = styled.div<{ hide: boolean; inModal?: boolean }>`
  width: 12px;
  height: 100%;
  position: absolute;
  right: ${({ inModal }) => (inModal ? '11%' : '0')};
  top: 0;
  z-index: 99;
  opacity: ${({ hide }) => (hide ? 0 : 1)};
  transition: opacity 0.2s;

  background: linear-gradient(90deg, #ffffff00, #ffffff);
`;

const findTableTagLength = (node: Elvita.Topic, part: string, cell: string) => {
  const headRow = node?.children
    .find(child => child?.name === part)
    ?.children.find(tr => tr?.name === 'tr');
  return headRow?.children.filter(th => th?.name === cell).length;
};

type TableProps = {
  node: Elvita.Topic;
  children: React.ReactNode;
};

const Table: FC<TableProps> = ({ node, children }) => {
  const { attribs } = node;
  const [showModal, setShowModal] = React.useState(false);
  const [tableWidth, setTableWidth] = React.useState(0);
  const [loading, setLoading] = React.useState(false);
  const [scrollStart, setScrollStart] = React.useState(true);
  const [scrollEnd, setScrollEnd] = React.useState(false);
  const [hideGradient, setHideGradient] = React.useState(false);
  const wrapperRef = React.useRef<HTMLDivElement | null>(null);
  const tableRef = React.useRef<HTMLTableElement | null>(null);

  const expandeble = () => {
    if (
      findTableTagLength(node, 'thead', 'th') > 2 ||
      findTableTagLength(node, 'tbody', 'td') > 2
    ) {
      return true;
    }

    return false;
  };

  const toggleModal = React.useCallback(
    () => setShowModal(!showModal),
    [showModal],
  );

  const handleModalLoad = React.useCallback(
    (isLoading: boolean, value?: number) => {
      setLoading(isLoading);
      value > 0 && setTableWidth(value);
    },
    [],
  );

  React.useEffect(() => {
    setTimeout(() => {
      setHideGradient(
        wrapperRef?.current.offsetWidth >= tableRef?.current.offsetWidth,
      );
    }, 300);
  }, []);

  return (
    <Wrapper ref={wrapperRef}>
      {expandeble() && (
        <ExpanderIcon src="/images/expander.svg" onClick={toggleModal} />
      )}
      <div
        style={{
          overflow: 'auto',
        }}
        onScroll={(event: React.UIEvent<HTMLElement>) => {
          setScrollEnd(
            event.currentTarget.scrollLeft + event.currentTarget.offsetWidth >
              event.currentTarget.scrollWidth - 10,
          );
          setScrollStart(event.currentTarget.scrollLeft === 0);
        }}
      >
        <StartGradient hide={hideGradient || scrollStart} />
        <TableWrapper
          className={attribs.class}
          grid={attribs.class === 'grid-table'}
          standard={attribs.class === 'standard-table'}
          id={attribs.id}
          ref={tableRef}
        >
          {children}
        </TableWrapper>
        <EndGradient hide={hideGradient || scrollEnd} />
      </div>
      <Modal
        isVisible={showModal}
        onClose={toggleModal}
        width="90%"
        handleLoad={(isLoading, value) => handleModalLoad(isLoading, value)}
        scrollStart={scrollStart}
        scrollEnd={scrollEnd}
        isTableModal={true}
      >
        {!loading && (
          <Content
            onScroll={(event: React.UIEvent<HTMLElement>) => {
              setScrollEnd(
                event.currentTarget.scrollLeft +
                  event.currentTarget.offsetWidth >
                  event.currentTarget.scrollWidth - 10,
              );
              setScrollStart(event.currentTarget.scrollLeft === 0);
            }}
          >
            <TableWrapper
              className={attribs.class}
              grid={attribs.class === 'grid-table'}
              standard={attribs.class === 'standard-table'}
              id={attribs.id}
              width={tableWidth}
            >
              {children}
            </TableWrapper>
          </Content>
        )}
      </Modal>
    </Wrapper>
  );
};

export default Table;
