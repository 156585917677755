import React from 'react';
import { useFetchHotspots } from './useFetchData';

export function useHotspot(hotspotId: string): Elvita.Hotspot | undefined {
  const { error, data } = useFetchHotspots();

  const [hotspot, setHotspot] = React.useState<Elvita.Hotspot | undefined>(
    undefined,
  );

  React.useEffect(() => {
    if (data && !error) {
      setHotspot(
        data.find(
          item =>
            item.id.slice(
              item.id.lastIndexOf('/') + 1,
              item.id.indexOf('.'),
            ) === hotspotId,
        ),
      );
    }
  }, [data, hotspotId, error]);

  return hotspot;
}
