import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const StyledLink = styled(Link)`
  font-size: 15px;
  line-height: ${({ theme }) => theme.lineHeights.xxs};
  color: ${({ theme }) => theme.colors.elvitaWebPurple};
  font-weight: 300;

  &:hover {
    text-decoration: underline;
  }

  &:active {
    color: ${({ theme }) => theme.colors.elvitaWebPink};
    text-decoration: none;
  }
`;

type AnchorProps = {
  href: string;
  children: React.ReactNode;
  data: string;
};

const Anchor: FC<AnchorProps> = ({ href, data }) => {
  return <StyledLink to={href}>{data}</StyledLink>;
};

export default Anchor;
