import React, { FC } from 'react';
import styled from 'styled-components';
import { ReactComponent as WarningIcon } from '../../components/icons/warning.svg';
import { ReactComponent as CautionIcon } from '../../components/icons/caution.svg';
import { ReactComponent as NoteIcon } from '../../components/icons/note.svg';
import { ReactComponent as DangerIcon } from '../../components/icons/danger.svg';
import { ReactComponent as TipIcon } from '../../components/icons/tip.svg';
import { useTranslation } from 'src/hooks/useTranslation';

const getType = (classes: string): Elvita.AttentionTypes => {
  let type: Elvita.AttentionTypes;
  const attentionTypes: Elvita.AttentionTypes[] = [
    'warning',
    'caution',
    'note',
    'danger',
    'tip',
  ];
  for (const key in attentionTypes) {
    if (classes.includes(attentionTypes[key])) {
      type = attentionTypes[key];
    }
  }
  return type;
};

const getAttentionIcon = (type: Elvita.AttentionTypes) => {
  if (type === 'warning') {
    return <WarningIcon />;
  } else if (type === 'caution') {
    return <CautionIcon />;
  } else if (type === 'note') {
    return <NoteIcon />;
  } else if (type === 'danger') {
    return <DangerIcon />;
  } else if (type === 'tip') {
    return <TipIcon />;
  }
};

const Wrapper = styled.div`
  display: flex;
  margin: ${({ theme }) => `0  0 16px -${theme.space.s}`};
  border-left-width: ${({ theme }) => theme.borderWidths.attention}px;
  border-left-style: solid;

  &.warning {
    border-left-color: ${({ theme }) => theme.colors.elvitaRed};
  }
  &.caution {
    border-left-color: ${({ theme }) => theme.colors.elvitaPurpleDark};
  }
  &.note {
    border-left-color: ${({ theme }) => theme.colors.elvitaGray};
  }
  &.danger {
    border-left-color: ${({ theme }) => theme.colors.elvitaRed};
  }
  &.tip {
    border-left-color: ${({ theme }) => theme.colors.elvitaMidGray};
  }

  @media (min-width: ${({ theme }) => theme.screens.md}) {
    margin-left: 0;
  }

  ul &,
  ol & {
    border-left-width: 2px;
    margin-top: ${({ theme }) => theme.space.xs};
    margin: 0 0 16px 0;
  }
`;

const AttentionLabelWrapper = styled.div`
  display: flex;
  flex-direction: row;

  ul &,
  ol & {
    margin-bottom: 8px;
    display: inline;

    @media (min-width: ${({ theme }) => theme.screens.md}) {
      margin-left: 0;
    }
  }
`;

const TextWrapper = styled.div`
  padding-left: ${({ theme }) => theme.space.sm};

  ul &,
  ol & {
    padding-left: ${({ theme }) => theme.space.xxs};
  }
`;

const AttentionLabel = styled.span`
  font-family: ${({ theme }) => theme.fonts.regular};
  font-size: ${({ theme }) => theme.fontSizes.m};
  font-weight: 700;
  letter-spacing: 0.06em;
  text-transform: uppercase;
  margin-left: ${({ theme }) => theme.space.xxxs};

  ul &,
  ol & {
    display: inline;
  }
`;

const IconWrapper = styled.span`
  ul &,
  ol & {
    display: none;
  }
  svg {
    width: 18.69px;
    height: 20.74px;
  }
`;

type AttentionProps = {
  className: string;
  children: React.ReactNode;
};

const Attention: FC<AttentionProps> = ({ className, children }) => {
  const { t } = useTranslation();
  const type = getType(className);

  return (
    <Wrapper className={className}>
      <TextWrapper>
        <AttentionLabelWrapper>
          <IconWrapper>{getAttentionIcon(type)}</IconWrapper>
          <AttentionLabel>
            {type === 'tip' ? t(`attention.hint`) : t(`attention.${type}`)}!
          </AttentionLabel>
        </AttentionLabelWrapper>
        {children}
      </TextWrapper>
    </Wrapper>
  );
};

export default Attention;
