import { create } from 'zustand';
import { persist } from 'zustand/middleware';

interface LanguageState {
  lang: string;
  setLang: (langCode: string) => void;
}

const languageDomains = [
  { domainSuffix: 'dk', langCode: 'da-DK' },
  { domainSuffix: 'de', langCode: 'de-DE' },
  { domainSuffix: 'fi', langCode: 'fi-FI' },
  { domainSuffix: 'is', langCode: 'is-IS' },
  { domainSuffix: 'no', langCode: 'nb-NO' },
  { domainSuffix: 'se', langCode: 'sv-SE' },
  { domainSuffix: 'com', langCode: 'en-GB' },
];

export const getDomainLanguage = () => {
  const currentHost = window.location.hostname;
  const hostArray = currentHost.split('.');
  const suffix = hostArray[hostArray.length - 1];

  const foundLang = languageDomains.find(lang => lang.domainSuffix === suffix);
  return foundLang ? foundLang.langCode : 'sv-SE';
};

export const useLanguageStore = create<LanguageState>()(
  persist(
    set => ({
      lang: getDomainLanguage(),
      setLang: langCode =>
        set(state => {
          if (languageDomains.find(lang => lang.langCode === langCode)) {
            return { lang: langCode };
          }
        }),
    }),
    {
      name: 'lang-storage',
      partialize(state) {
        return {
          lang: state.lang,
        };
      },
    },
  ),
);
