import * as React from 'react';
import { useTranslation } from '../hooks/useTranslation';
import Portal from './Portal';
import Sidebar from './Sidebar';

type RelatedContentProps = {
  node?: Elvita.Topic;
  jsonRelations?: Elvita.Node[];
  portalId: string;
};

const RelatedContent: React.FC<RelatedContentProps> = ({
  node,
  jsonRelations,
  portalId,
}) => {
  const { t } = useTranslation();
  const items: Elvita.Node[] = jsonRelations ? jsonRelations : [];
  const relations = node?.children.find(
    child => child.attribs && child.attribs.class.includes('relations'),
  );

  if (relations) {
    relations.children.map(child => {
      if (child.attribs && child.attribs.class.includes('rel')) {
        items.push({
          topicId: child.attribs.href ?? '',
          title: child.children[0].data ?? '',
          overview: '',
        });
      }
    });
  }

  return (
    <Portal id={portalId}>
      <Sidebar items={items} title={t('topic.relatedContent')} />
    </Portal>
  );
};

export default RelatedContent;
