import * as React from 'react';
import styled from 'styled-components';
import consts from '../../config/consts';

const Wrapper = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
`;

const Inner = styled.div`
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  width: 250px;
  height: 250px;
`;

const PlayIcon = styled.div`
  position: absolute;
  background-image: url(/images/play.svg);
  width: 42px;
  height: 42px;
  background-size: cover;

  ${Inner}:hover & {
    background-image: url(/images/play-active.svg);
  }
`;

const StyledVideo = styled.video`
  display: flex;
  width: 250px;
`;

type VideoData = {
  posterSrc: string;
  videoSrc: string;
  videoType: string;
};

type VideoProps = {
  node: Elvita.Topic;
};

const Video: React.FC<VideoProps> = ({ node }) => {
  const videoRef = React.useRef<HTMLVideoElement | null>(null);
  const [isPlaying, setIsPlaying] = React.useState(false);
  const [videoData, setVideoData] = React.useState<VideoData>(undefined);

  // React.useEffect(() => {
  //   const handleScroll = async () => {
  //     if (window.scrollY >= videoRef?.current.offsetTop && videoRef?.current) {
  //       try {
  //         await videoRef?.current.play();
  //         setIsPlaying(true);
  //       } catch (error) {
  //         console.log('Error playing video => ', error);
  //       }
  //     }
  //   };

  //   window.addEventListener('scroll', handleScroll);
  // }, []);

  React.useEffect(() => {
    if (node.name === 'video') {
      const source = node.children.find(child => child.name === 'source');

      setVideoData({
        posterSrc: node?.attribs.poster,
        videoSrc: source?.attribs.src,
        videoType: source?.attribs.type,
      });
    }
  }, []);

  const handleVideoClick = () => {
    videoRef?.current.paused
      ? videoRef?.current.play()
      : videoRef?.current.pause();
    setIsPlaying(!videoRef?.current.paused);
  };

  const handleVideoEnded = () => {
    setIsPlaying(false);
  };

  if (!videoData || !videoData?.posterSrc) {
    return null;
  }

  return (
    <Wrapper>
      <Inner onClick={handleVideoClick}>
        {!isPlaying && <PlayIcon />}
        <StyledVideo
          poster={`${consts.storageUrl}images/${videoData.posterSrc}` ?? ''}
          ref={videoRef}
          src={
            `${consts.storageUrl}${consts.videoPath}/${videoData.videoSrc}` ??
            ''
          }
          muted
          preload="metadata"
          playsInline
          onEnded={handleVideoEnded}
        />
      </Inner>
    </Wrapper>
  );
};

export default Video;
