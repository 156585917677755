import { useInternalLink } from 'src/hooks/useInternalLink';
import { useLinkFromId } from './link';
import consts from '../config/consts';

export function useLink(
  item: Elvita.HomePageLink | Elvita.MenuItem,
  topicId: string,
  data: Elvita.Node[],
): string {
  let link: string;
  const { internalLink } = useInternalLink();
  const idLink = useLinkFromId(data, item.target);

  if (topicId === item.target) {
    // Item target is current product
    return internalLink('/browse');
  }

  switch (item.type) {
    case 'browse':
      link = internalLink(`/browse/${item.target}`);
      break;
    case 'topic':
      link = idLink;
      break;
    case 'file':
      link = `${consts.storageUrl}${consts.filePath}${item.target}`;
      break;

    default:
      link = '';
      break;
  }

  return link;
}
