import * as React from 'react';
import { useParams } from 'react-router-dom';
import Breadcrumbs from '../components/Breadcrumbs';
import { MainContent } from '../components/layout/MainContent';
import { PageContentRow } from '../components/layout/PageContentRow';
import { useFetchBrowse } from '../hooks/useFetchData';
import { useNodes } from '../hooks/useNodes';
import Page from '../components/layout/Page';
import PageContentWrapper from '../components/layout/PageContentWrapper';
import TopicContent from '../components/TopicContent';
import styled from 'styled-components';
import { getSiblings } from '../helpers/node';
import Sidebar from '../components/Sidebar';
import { useTranslation } from 'src/hooks/useTranslation';
import { useMetaTitle } from 'src/hooks/useMetaTitle';
import { useMatomo } from '@datapunt/matomo-tracker-react';

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;

  @media (min-width: ${({ theme }) => theme.screens.l}) {
    flex-direction: row;
    padding: ${({ theme }) => `0 ${theme.space.md}`};
    width: 100%;
  }
`;

const SidebarWrapper = styled.div`
  order: 1;
  display: flex;
  flex-direction: column;
  margin: ${({ theme }) => `0 -${theme.space.s}`};
  background-color: ${({ theme }) => theme.colors.elvitaLighterGray};
  padding: ${({ theme }) =>
    `18px ${theme.space.s} ${theme.space.xl} ${theme.space.s}`};

  @media (min-width: ${({ theme }) => theme.screens.md}) {
    flex-direction: row;
    padding: ${({ theme }) =>
      `18px ${theme.space.md} ${theme.space.xl} ${theme.space.md}`};
  }

  @media (min-width: ${({ theme }) => theme.screens.l}) {
    background-color: transparent;
    flex-direction: column;
    width: 28%;
    padding: ${({ theme }) =>
      `${theme.space.xs} ${theme.space.s} ${theme.space.xl} ${theme.space.s}`};
  }
`;

const Topic: React.FC = () => {
	const { trackPageView } = useMatomo();
  const { topicId, path } = useParams<{ topicId: string; path: string }>();
  const { data } = useFetchBrowse();
  const { t } = useTranslation();
  useMetaTitle();
  const siblings =
    getSiblings(topicId, data?.data[0]).filter(
      (sibling: Elvita.Node) => sibling && sibling?.topicId,
    ) ?? [];

  const topLevel =
    data?.data && data.data.length > 0
      ? data?.data[0].children.find(item => item.topicId === path)
      : undefined;

  const { breadcrumbs } = useNodes(
    topLevel,
    topicId,
    path,
    topLevel?.title ?? '',
  );

  React.useEffect(() => {
	trackPageView(null);
  }, [topicId]);

  return (
    <Page>
      <PageContentWrapper flex={1}>
        <PageContentRow center={true}>
          <MainContent>
            <Breadcrumbs
              breadcrumbs={breadcrumbs.slice(0, -1)}
              hideLast={true}
            />
          </MainContent>
        </PageContentRow>
        <ContentWrapper>
          <TopicContent topicId={topicId} />
          <SidebarWrapper>
            {siblings.length > 0 && (
              <Sidebar items={siblings} title={t('topic.siblingContent')} />
            )}
            <div
              id="related"
              style={{
                flex: 1,
              }}
            />
          </SidebarWrapper>
        </ContentWrapper>
      </PageContentWrapper>
    </Page>
  );
};

export default Topic;
