import * as React from 'react';
import { useParams } from 'react-router-dom';
import queryString from 'query-string';

export function useInternalLink() {
  const { productId } = useParams<{ productId: string }>();
  const { user } = queryString.parse(window.location.search);
  const userParam = user ? `?user=${user}` : '';

  const internalLink = React.useCallback(
    (url: string) => {
      return productId
        ? `/${productId}${url}${userParam}`
        : `${url}${userParam}`;
    },
    [productId, userParam],
  );

  return { internalLink };
}
