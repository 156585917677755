import * as React from 'react';
import styled, { css } from 'styled-components';

const withRelatedContentStyles = css`
  flex-direction: row;
  flex: unset;
`;

const Wrapper = styled.div<{
  as?: 'main' | 'header' | 'footer';
  flex: number;
  alignSelf: string;
  spaceBetween: boolean;
  withRelatedContent: boolean;
  noPadding: boolean;
  hasGradientBackground: boolean;
}>`
  max-width: ${({ theme }) => theme.sizes.containerWide};
  width: 100%;
  display: flex;
  align-self: ${({ alignSelf }) => (alignSelf ? alignSelf : 'center')};
  flex-direction: column;
  flex: ${({ flex }) => flex && flex};
  padding: ${({ theme, noPadding }) => !noPadding && `0 ${theme.space.s}`};
  justify-content: ${({ spaceBetween }) =>
    spaceBetween ? 'space-between' : 'flex-start'};
  position: relative;

  @media (max-width: ${({ theme }) => theme.screens.md}) {
    position: ${({ as }) => as === 'header' && 'sticky'};
    top: ${({ as }) => as === 'header' && 0};
    z-index: ${({ as }) => as === 'header' && 100};
  }

  background-color: #fff;
  ${({ hasGradientBackground }) =>
    hasGradientBackground &&
    `
    background-size: 100% 451px;
    background-repeat: no-repeat;
    background-image: linear-gradient(180deg, #F2F2F2 0%, #DEE2E6 76.04%, rgba(242, 242, 242, 0) 100%);
  `}

  box-shadow: rgb(0 0 0 / 25%) 0px 0px 10px -8px;

  @media (min-width: ${({ theme }) => theme.screens.l}) {
    justify-content: flex-start;
    padding: 0;

    ${({ withRelatedContent }) =>
      withRelatedContent && withRelatedContentStyles};
  }
`;

type PageContentWrapperProps = {
  type?: 'main' | 'header' | 'footer';
  flex?: number;
  alignSelf?: 'flex-start';
  spaceBetween?: boolean;
  withRelatedContent?: boolean;
  noPadding?: boolean;
};

const PageContentWrapper: React.FC<PageContentWrapperProps> = ({
  type = 'main',
  children,
  flex,
  alignSelf,
  spaceBetween,
  withRelatedContent,
  noPadding,
}) => {
  return (
    <Wrapper
      as={type}
      flex={flex}
      alignSelf={alignSelf}
      spaceBetween={spaceBetween}
      withRelatedContent={withRelatedContent}
      noPadding={noPadding}
      hasGradientBackground={false}
    >
      {children}
    </Wrapper>
  );
};

export default PageContentWrapper;
