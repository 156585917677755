import * as React from 'react';
import { Link, useParams } from 'react-router-dom';
import { AnchorLink } from 'src/components/typography/AnchorLink';
import consts from 'src/config/consts';
import { useMetaTitle } from 'src/hooks/useMetaTitle';
import styled from 'styled-components';
import Breadcrumbs from '../components/Breadcrumbs';
import { MainContent } from '../components/layout/MainContent';
import Page from '../components/layout/Page';
import PageContentWrapper from '../components/layout/PageContentWrapper';
import ProductList from '../components/ProductList';
import { getItemTitle, isWizard } from '../helpers/wizard';
import { useFetchWizardStructure } from '../hooks/useFetchData';
import { useNodes } from '../hooks/useNodes';
import { useTranslation } from '../hooks/useTranslation';
import { useMatomo } from '@datapunt/matomo-tracker-react';

const StyledMainContent = styled(MainContent)`
  margin-bottom: ${({ theme }) => theme.space.l};
`;

const Hero = styled.div`
  position: relative;
  margin: ${({ theme }) => `0 -${theme.space.s}`};

  @media (min-width: ${({ theme }) => theme.screens.l}) {
    margin: 0;
  }
`;

const HeroImage = styled.div`
  width: 100%;
  height: 180px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 0 50%;
  background-color: ${({ theme }) => theme.colors.elvitaLighterGray};

  @media (min-width: ${({ theme }) => theme.screens.md}) {
    height: 213px;
    background-position: 0px 45%;
  }

  @media (min-width: ${({ theme }) => theme.screens.l}) {
    height: 248px;
  }
`;

const TitleBgGradient = styled.div`
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.8),
    rgba(255, 255, 255, 0.5) 25%,
    rgba(255, 255, 255, 0) 60%
  );
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 111px;
  padding: ${({ theme }) => theme.space.s};
`;

const Title = styled.div`
  font-family: ${({ theme }) => theme.fonts.bold};
  font-size: ${({ theme }) => theme.fontSizes.ml};
  line-height: 24px;
  letter-spacing: 0.06em;
  color: ${({ theme }) => theme.colors.elvitaTrueBlack};

  max-width: 50%;

  @media (min-width: ${({ theme }) => theme.screens.md}) {
    font-size: 28px;
    line-height: 33.6px;
  }

  @media (min-width: ${({ theme }) => theme.screens.l}) {
    font-size: 32px;
    line-height: 38.4px;
  }
`;

const SearchButton = styled(Link)`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: 14px;
  bottom: 17px;
  background-color: #fff;
  width: 44px;
  height: 44px;
  border-radius: 44px;

  @media (min-width: ${({ theme }) => theme.screens.md}) {
    display: none;
  }
`;

const ComingSoonTitle = styled(Title)`
  margin: ${p => p.theme.space.s} 0;
`;

const ComingSoonText = styled.p`
  font-family: ${p => p.theme.fonts.light};
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 17px;
`;

const SearchIcon = styled.img``;

const Wizard: React.FC = () => {
	const { trackPageView } = useMatomo();
  const { t } = useTranslation();
  const { path, childId } = useParams<{
    path: string;
    childId: string;
  }>();
  const { error, data } = useFetchWizardStructure();
  const [topLevel, setTopLevel] = React.useState<Elvita.WizardItem | undefined>(
    undefined,
  );
  useMetaTitle();

  React.useEffect(() => {
    if (data && data.status === 200) {
      setTopLevel(data?.data.find(item => item.id === path));
    }
  }, [data, path]);

  React.useEffect(() => {
	trackPageView(null);
  }, [data]);
  
  const { node, breadcrumbs } = useNodes(topLevel, childId, path, path);
  
  if (error || !node) {
	  return null;
	}
	
  return (
    <Page hideMenu={true} hideSearch={true} isWizard={true}>
      <PageContentWrapper flex={1}>
        <Breadcrumbs
          inWizard={true}
          hideLast={true}
          breadcrumbs={breadcrumbs.slice(0, -1).map(crumb => {
            return {
              label: getItemTitle(
                crumb.label,
                data.data,
                path !== crumb.label ? path : undefined,
              ),
              url: crumb.url,
            };
          })}
        />
        <Hero>
          <TitleBgGradient>
            <Title as="h1">{node && isWizard(node) && node.title}</Title>
          </TitleBgGradient>
          <HeroImage
            style={{
              backgroundImage: `url(${consts.storageUrl}images/${
                isWizard(node) && node.imageId
              }`,
            }}
          />
          <SearchButton to="/search/product">
            <SearchIcon src="/images/search-icon.svg" />
          </SearchButton>
        </Hero>
        <StyledMainContent>
          {node?.children.length === 0 && (
            <>
              <ComingSoonTitle as="h2">
                {t('wizard.nocontent.title')}
              </ComingSoonTitle>
              <ComingSoonText>
                {t('wizard.nocontent.description')}{' '}
                <AnchorLink href={t('wizard.nocontent.link.href')}>
                  {t('wizard.nocontent.link.text')}
                </AnchorLink>
              </ComingSoonText>
            </>
          )}
          <ProductList
            items={node?.children as Elvita.WizardItem[]}
            title={t('search.categoryList') ?? 'search.categoryList'}
          />
        </StyledMainContent>
      </PageContentWrapper>
    </Page>
  );
};

export default Wizard;
