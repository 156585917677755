import React from 'react';
import H1 from '../typography/content/H1';
import H2 from '../typography/H2';
import H3 from '../typography/H3';
import Text from '../typography/Text';
import Paragraph from '../topic/Paragraph';
import Attention from '../topic/Attention';
import styled from 'styled-components';
import RelatedContent from '../RelatedContent';
import TopicHotspot from './TopicHotspot';
import MediaList from './Medialist';
import { getMedialistCarouselData } from '../../helpers/topic';

type DefaultWrapperProps = {
  id: string;
  className: string;
};

const StyledH1 = styled(H1)`
  margin-bottom: ${({ theme }) => theme.space.md};
`;

const StyledH3 = styled(H3)`
  margin-bottom: ${({ theme }) => theme.space.s};
`;

const DefaultWrapper = styled.div<DefaultWrapperProps>`
  margin-bottom: ${({ theme }) => theme.space.m};

  ul &,
  ol & {
    margin-bottom: 0;
  }
`;

const ImageCaption = styled(Text)`
  font-style: italic;
  color: headers;
`;

const Figure = styled.div`
  width: fit-content;
  margin: 0 0 ${({ theme }) => theme.space.s};
`;

const FigureTitle = styled(Text)`
  font-style: italic;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: ${({ theme }) => theme.colors.elvitaMediumGrey};
  text-align: right;
  margin-bottom: ${({ theme }) => theme.space.s};
`;

const FaultCode = styled(H2)`
  position: relative;
  float: left;
  display: inline-block;
  border: 1px solid ${({ theme }) => theme.colors.elvitaTrueBlack};
  width: 80px;
  padding: 0 5px;
  margin: 5px 5px 5px 0;

  &:before {
    position: absolute;
    content: 'Event Code';
    left: -2px;
    top: -22px;
    font-size: 10px;
    text-transform: uppercase;
  }
`;

type DivProps = {
  node: Elvita.Topic;
  children: React.ReactNode;
};

export function hasClass(classList: string, className: string): string {
  return classList && classList.includes(className) ? classList : '';
}

const Div = ({ node, children }: DivProps) => {
  switch (node.attribs.class) {
    case hasClass(node.attribs.class, 'attention'):
      return <Attention className={node.attribs.class}>{children}</Attention>;

    case 'title':
      return node.parent &&
        hasClass(node.parent.attribs.class, 'section') == 'section' ? (
        <StyledH3 className={node.attribs.class}>{children}</StyledH3>
      ) : (
        <StyledH1 className={node.attribs.class}>{children}</StyledH1>
      );

    case 'title h2':
      return <H2 className={node.attribs.class}>{children}</H2>;

    case 'title h3':
    case 'bridgehead':
      return <H3 className={node.attribs.class}>{children}</H3>;

    case 'figcaption':
      return <ImageCaption>{children}</ImageCaption>;

    case 'figure':
      return <Figure>{children}</Figure>;

    case 'figure-title':
      return <FigureTitle>{children}</FigureTitle>;

    case 'para':
      return (
        <Paragraph id={node.attribs.id} className={node.attribs.class}>
          {children}
        </Paragraph>
      );

    case 'meta':
      return <RelatedContent node={node} portalId="#related" />;

    case 'imagemap':
      const parent = node?.parent;

      if (parent && parent?.attribs?.class.includes('topic')) {
        return <TopicHotspot hotspotId={parent.attribs.id} />;
      } else {
        return (
          <DefaultWrapper
            data-template={node.attribs['data-template']}
            id={node.attribs.id}
            className={`${node.attribs.class}`}
          >
            {children}
          </DefaultWrapper>
        );
      }

    case 'topic':
      if (node.attribs['data-faultcode']) {
        return (
          <DefaultWrapper id={node.attribs.id} className={node.attribs.class}>
            <FaultCode>{node.attribs['data-faultcode']}</FaultCode>
            {children}
          </DefaultWrapper>
        );
      } else {
        return (
          <DefaultWrapper
            data-template={node.attribs['data-template']}
            id={node.attribs.id}
            className={`${node.attribs.class}`}
          >
            {children}
          </DefaultWrapper>
        );
      }

    case 'medialist':
      const data = getMedialistCarouselData(node);

      if (data.items.length > 0) {
        return <MediaList items={data.items} id={data.id} />;
      } else {
        return (
          <DefaultWrapper id={node.attribs.id} className={node.attribs.class}>
            {children}
          </DefaultWrapper>
        );
      }

    default:
      return (
        <DefaultWrapper id={node.attribs.id} className={node.attribs.class}>
          {children}
        </DefaultWrapper>
      );
  }
};

export default Div;
