import * as React from 'react';
import { useLink } from '../helpers/menu';
import { useFetchBrowse } from '../hooks/useFetchData';
import styled from 'styled-components';
import { Link, useLocation, useParams } from 'react-router-dom';
import { isActive } from '../helpers/link';

const StyledLink = styled(Link)<{ $active?: boolean }>`
  display: flex;
  align-items: center;
  font-family: ${({ theme, $active }) =>
    $active ? theme.fonts.bold : theme.fonts.light};
`;

type MenuLinkProps = {
  item: Elvita.MenuItem;
};

const MenuLink: React.FC<MenuLinkProps> = ({ item, children }) => {
  const { pathname } = useLocation();
  const { productId } = useParams<{ productId: string }>();
  const { error, data } = useFetchBrowse();
  const link = useLink(item, data?.data[0].topicId, data?.data);

  if (error) {
    return null;
  }

  return (
    <StyledLink
      to={link}
      $active={
        isActive(item.target, pathname) ||
        (pathname === `/${productId}/browse` &&
          item.target === data?.data[0].topicId)
      }
    >
      {children}
    </StyledLink>
  );
};

export default MenuLink;
