import * as React from 'react';
import parse, { domToReact } from 'html-react-parser';
import Anchor from './topic/Anchor';
import Div from './topic/Div';
import Img from './topic/Img';
import Table from './topic/Table';
import Paragraph from './topic/Paragraph';
import List from './topic/List';
import Span from './topic/Span';
import Procedure from './topic/Procedure';
import Video from './topic/Video';
import { getProcedureCarouselData } from '../helpers/topic';
import ExternalAnchor from './topic/ExternalAnchor';

type StringToComponentProps = {
  data: string;
};

const StringToComponent: React.FC<StringToComponentProps> = ({ data }) => {
  const [ReactComponent, setReactComponent] = React.useState<
    string | JSX.Element | JSX.Element[]
  >('');

  React.useEffect(() => {
    if (data) {
      const options = {
        replace: node => {
          switch (node.name) {
            case 'a': {
              if (node.attribs.class === 'xref') {
                return (
                  <Anchor href={node.attribs.href} data={node.children[0].data}>
                    {domToReact(node.children, options)}
                  </Anchor>
                );
              } else {
                const href = node.attribs.href.startsWith('www.')
                  ? 'http://' + node.attribs.href
                  : node.attribs.href;
                return (
                  <ExternalAnchor href={href} data={node.children[0].data}>
                    {domToReact(node.children, options)}
                  </ExternalAnchor>
                );
              }
            }
            case 'div':
              return (
                <Div node={node}>{domToReact(node.children, options)}</Div>
              );
            case 'img':
              return (
                <Img imageId={node.attribs['src']} node={node}>
                  {domToReact(node.children, options)}
                </Img>
              );

            case 'table': {
              return (
                <Table node={node}>{domToReact(node.children, options)}</Table>
              );
            }

            case 'p':
              return (
                <Paragraph>{domToReact(node.children, options)}</Paragraph>
              );

            case 'ul':
            case 'ol':
              const procedureData = getProcedureCarouselData(node, data);

              if (procedureData.data.length > 0) {
                return (
                  <Procedure items={procedureData.data} id={procedureData.id} />
                );
              }

              return (
                <List node={node}>{domToReact(node.children, options)}</List>
              );

            case 'span':
              return (
                <Span className={node.attribs.class}>
                  {domToReact(node.children, options)}
                </Span>
              );

            case 'video':
              return <Video node={node} />;

            default:
              return node;
          }
        },
      };
      setReactComponent(parse(data, options));
    }
  }, [data]);

  if (!data) {
    return null;
  }

  return <>{ReactComponent}</>;
};

export default StringToComponent;
