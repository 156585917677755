import * as React from 'react';
import styled, { css } from 'styled-components';

const InactiveStyle = css`
  border: 1px solid ${({ theme }) => theme.colors.elvitaGray20};
  color: ${({ theme }) => theme.colors.elvitaGray20};
  pointer-events: none;
`;

const ButtonWrapper = styled.button<{
  inactive: boolean;
  hasIcon: boolean;
  secondary: boolean;
}>`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: ${({ theme, secondary }) =>
    secondary ? theme.space.xxs : theme.space.xs};
  border-radius: ${({ theme, secondary }) =>
    secondary ? theme.radii.s : theme.radii.xs};
  font-family: ${({ theme }) => theme.fonts.light};
  font-size: ${({ theme }) => theme.fontSizes.m};
  line-height: 1;
  padding-top: 7px;
  border: 1px solid
    ${({ theme, secondary }) =>
      secondary ? theme.colors.elvitaWebPurple : theme.colors.elvitaPurpleDark};
  color: ${({ theme, secondary }) =>
    secondary ? theme.colors.elvitaWebPurple : theme.colors.elvitaPurpleDark};
  text-transform: ${({ secondary }) => secondary && 'uppercase'};

  &:hover {
    border: 1px solid ${({ theme }) => theme.colors.elvitaWebPink};
    color: ${({ theme }) => theme.colors.elvitaWebPink};
  }

  ${({ inactive }) => inactive && InactiveStyle};
`;

type ButtonProps = {
  text: string;
  icon?: React.ReactChild;
  secondary?: boolean;
  onClick: () => void;
  className?: string;
};

const Button: React.FC<ButtonProps> = ({
  text,
  icon,
  secondary,
  onClick,
  className,
}) => {
  return (
    <ButtonWrapper
      inactive={false}
      hasIcon={icon ? true : false}
      secondary={secondary}
      onClick={onClick}
      className={className}
    >
      {icon && icon}
      {text}
    </ButtonWrapper>
  );
};

export default Button;
