import * as React from 'react';
import styled from 'styled-components';
import { SwiperSlide } from 'swiper/react';
import Carousel from '../Carousel';
import StringToComponent from '../StringToComponent';
import Img from './Img';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 -14px;

  .swiper-slide {
    height: 90%;
  }

  .swiper-slide-next,
  .swiper-slide-prev {
    position: relative;

    img {
      height: 100%;
    }
  }

  .swiper-slide-next {
    img {
      position: absolute;
      left: -30px;

      @media (min-width: ${({ theme }) => theme.screens.sm}) {
        left: -55px;
      }
    }
  }

  .swiper-slide-prev {
    img {
      position: absolute;
      right: -30px;

      @media (min-width: ${({ theme }) => theme.screens.sm}) {
        right: -55px;
      }
    }
  }

  @media (min-width: ${({ theme }) => theme.screens.md}) {
    margin: unset;
  }
`;

const ActionWrapper = styled.div`
  display: flex;
  flex-direction: row;
  padding: ${({ theme }) => `0 ${theme.space.s}`};
  align-self: center;
  width: 100%;
`;

const CarouselIndex = styled.div`
  font-size: ${({ theme }) => theme.fontSizes.s};
  line-height: 16.8px;
  font-weight: 700;
  color: ${({ theme }) => theme.colors.elvitaTrueBlack};
  padding-right: ${({ theme }) => theme.space.xs};
`;

type ProcedureProps = {
  items: Elvita.StepData[];
  id: string;
};

const Procedure: React.FC<ProcedureProps> = ({ items, id }) => {
  const [carouselIndex, setCarouselIndex] = React.useState(0);

  return (
    <Wrapper>
      <Carousel
        onIndexChange={value => setCarouselIndex(value)}
        itemsLength={items.length}
        className={id.replace('.', '')}
      >
        {items.map((item, index) => (
          <SwiperSlide
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
            }}
            key={index}
          >
            <Img imageId={item.id} node={item.node} isCarousel />
          </SwiperSlide>
        ))}
      </Carousel>
      <ActionWrapper>
        <CarouselIndex>{carouselIndex + 1}</CarouselIndex>
        {items[carouselIndex]?.data && items[carouselIndex].data.length && (
          <StringToComponent data={items[carouselIndex].data} />
        )}
      </ActionWrapper>
    </Wrapper>
  );
};

export default Procedure;
