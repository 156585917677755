import { isWizard } from './wizard';

function buildLink(
  hasChildren: boolean,
  topLevel: string,
  nodeId: string,
  child: Elvita.Node | Elvita.WizardItem,
): string {
  if (isWizard(child)) {
    return `/wizard/${topLevel}/${nodeId}`;
  }
  return hasChildren
    ? `/browse/${topLevel}/${nodeId}`
    : `/${topLevel}/topics/${nodeId}`;
}

function getCrumbs(
  child: Elvita.Node | Elvita.WizardItem,
  topicId: string,
  topLevel: string,
) {
  const breadCrumbTitle = isWizard(child)
    ? child.id
    : child.title?.replace(/ /g, '');
  const title = isWizard(child) ? child.id : child.title;
  const nodeId = isWizard(child) ? child.id : child.topicId;

  const breadcrumbs = {
    [breadCrumbTitle]: [
      {
        label: title,
        url: buildLink(
          child.children && child.children.length > 0,
          topLevel,
          nodeId,
          child,
        ),
      },
    ],
  };

  let currentNode: Elvita.Node | Elvita.WizardItem = isWizard(child)
    ? child.id === topicId && child
    : child.topicId === topicId && child;

  function recurse(childNode: Elvita.Node | Elvita.WizardItem) {
    const children = childNode.children;

    for (const key in children) {
      const currentChild = children[key];

      if (
        (isWizard(currentChild) ? currentChild.id : currentChild.topicId) ===
        topicId
      ) {
        currentNode = children[key];
      }
      const childNodeId = isWizard(currentChild)
        ? currentChild.id
        : currentChild.topicId;
      const childNodeTitle = isWizard(childNode)
        ? childNode.id
        : childNode.title;

      if (breadcrumbs[breadCrumbTitle] && childNodeId === topicId) {
        if (
          !breadcrumbs[breadCrumbTitle].find(b => b.label === childNodeTitle)
        ) {
          breadcrumbs[breadCrumbTitle].push({
            label: childNodeTitle,
            url: childNode.children
              ? `/browse/${topLevel}/${childNodeId}`
              : `/${topLevel}/topics/${childNodeId}`,
          });
        }

        const childrenNodeTitle = isWizard(currentChild)
          ? currentChild.id
          : currentChild.title;

        breadcrumbs[breadCrumbTitle].push({
          label: childrenNodeTitle,
          url: children[key].children
            ? `/browse/${topLevel}/${childNodeId}`
            : `/${topLevel}/topics/${childNodeId}`,
        });
      }

      recurse(children[key]);
    }
  }

  if (
    isWizard(child)
      ? child.children && child.id !== topicId
      : child.children && child.topicId !== topicId
  ) {
    recurse(child);
  }

  return { breadcrumbs: breadcrumbs[breadCrumbTitle], currentNode };
}

export function getChildren(
  data: Elvita.Node,
  childId: string,
  topLevel?: string,
): { nodeData: Elvita.Node | undefined; breadcrumbs: Elvita.BreadCrumb[] } {
  let node = {
    currentNode: undefined,
    breadcrumbs: [],
  };

  data.children.map(child => {
    const { currentNode, breadcrumbs } = getCrumbs(child, childId, topLevel);
    if (currentNode) {
      node = { currentNode, breadcrumbs };
    }
  });

  return { nodeData: node.currentNode, breadcrumbs: node.breadcrumbs };
}

export function getSiblings(
  currentTopicId: string,
  browseList: Elvita.Node,
): Elvita.Node[] | [] {
  if (!browseList || !browseList.children || browseList.children.length < 1) {
    return [];
  }

  const siblings: Elvita.Node[] = [];

  const findSiblings = (
    currentTopicId: string,
    browseList: Elvita.Node,
  ): void => {
    browseList.children.forEach(child => {
      if (child && child.children) {
        findSiblings(currentTopicId, child);
      }

      if (child.topicId === currentTopicId) {
        browseList.children.map(c => {
          siblings.push(c);
        });
      }
    });
  };

  findSiblings(currentTopicId, browseList);
  return siblings;
}

export function getCurrentBrowseItem(
  browseList: Elvita.Node[],
  currentId: string,
): {
  topLevelItem: Elvita.Node | undefined;
  currentBrowseItem: Elvita.Node | undefined;
} {
  if (!browseList) {
    return undefined;
  }
  const list = browseList[0]?.children ?? [];
  let currentBrowseItem: Elvita.Node;
  let topLevelItem: Elvita.Node;

  list.forEach(topItem => {
    const searchBrowseTree = (browse: Elvita.Node[]): void => {
      browse.forEach(entry => {
        if (entry.topicId === currentId) {
          topLevelItem = topItem;
          currentBrowseItem = entry;
        } else {
          if (entry.children) {
            searchBrowseTree(entry.children);
          }
        }
      });
    };

    searchBrowseTree(topItem.children);
  });

  return { topLevelItem, currentBrowseItem };
}
