import * as React from 'react';
import styled from 'styled-components';
import { useNodes } from '../hooks/useNodes';
import Loader from './Loader';
import { useInternalLink } from '../hooks/useInternalLink';
import { Link } from 'react-router-dom';

const Item = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: ${({ theme }) => theme.colors.elvitaWebPurple};
  padding: ${({ theme }) =>
    `${theme.space.s}  ${theme.space.m} ${theme.space.s} 0`};
  border-bottom-width: 1px;
  border-bottom-color: ${({ theme }) => theme.colors.elvitaMidGray};

  @media (hover: hover) {
    &:hover {
      color: ${({ theme }) => theme.colors.elvitaWebPink};
    }
  }
  &:last-of-type {
    margin-bottom: ${({ theme }) => theme.space.xl};
  }
`;

const Title = styled.div`
  font-family: ${({ theme }) => theme.fonts.bold};
  font-size: ${({ theme }) => theme.fontSizes.s};
  line-height: 17px;
  letter-spacing: 0.05em;
`;

const CaretIcon = styled.img`
  transform: rotate(180deg);
`;

type BrowseContentProps = {
  parentNode: Elvita.Node;
  path: string;
  childId?: string;
  loading: boolean;
};

const BrowseContent: React.FC<BrowseContentProps> = ({
  parentNode,
  childId,
  path,
  loading,
}) => {
  const { node } = useNodes(parentNode, childId, path);

  if (node === undefined) {
    return null;
  }

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <>
          {node.children &&
            node.children.length > 0 &&
            node.children.map((child, index) => (
              <LinkItem item={child} key={index} parent={path} />
            ))}
        </>
      )}
    </>
  );
};

const LinkItem: React.FC<{ item: Elvita.Node; parent: string }> = ({
  item,
  parent,
}) => {
  const { internalLink } = useInternalLink();

  const getHref = (hasChildren: boolean | undefined, title: string): string => {
    return hasChildren
      ? parent
        ? `/browse/${parent}/${title}`
        : `/browse/${title}`
      : `/${parent}/topics/${title}`;
  };
  return (
    <Item
      to={internalLink(
        getHref(item.children && item.children.length > 0, item.topicId ?? ''),
      )}
    >
      <Title>{item.title}</Title>
      {item.children && <CaretIcon src="/images/caret.svg" alt="" />}
    </Item>
  );
};

export default BrowseContent;
