import React, { FC } from 'react';
import styled, { css } from 'styled-components';

const baseListStyling = css`
  margin-bottom: ${({ theme }) => theme.space.m};

  .attention & {
    margin-bottom: 0;
  }

  li {
    padding: 0;
    margin-bottom: ${({ theme }) => theme.space.xxs};
    color: ${({ theme }) => theme.colors.elvitaTrueBlack};
    font-size: ${({ theme }) => theme.fontSizes.s};
    line-height: 17px;
    color: ${({ theme }) => theme.colors.elvitaTrueBlack};

    font-weight: 300;

    &::marker {
      font-size: ${({ theme }) => theme.fontSizes.s};
      line-height: 17px;
    }
  }
`;

const LegendStyle = css`
  padding-left: ${({ theme }) => theme.space.l};
  counter-reset: item;

  > li {
    position: relative;
    list-style: none;
    margin-bottom: ${({ theme }) => theme.space.xxs};

    &::before {
      position: absolute;
      left: -${({ theme }) => theme.space.gutter};
    }
  }
`;

const AlphaLegendStyles = css`
  > li {
    &::before {
      counter-increment: item;
      content: counter(item, upper-latin) '.';
    }
  }
`;

const NumericLegendStyles = css`
  counter-reset: count;

  > li {
    &::before {
      counter-increment: count;
      content: counter(count) '.';
    }
  }
`;

const StepsStyle = css`
  list-style: decimal;
`;

const Ul = styled.ul`
  ${baseListStyling}
  padding-left: ${({ theme }) => theme.space.s};

  li {
    list-style: disc;

    &::marker {
      display: block;
      padding-left: 10px;
    }
  }
`;

type OlTypes = {
  alphaLegend: boolean;
  numericLegend: boolean;
  steps: boolean;
};

const Ol = styled.ol<OlTypes>`
  ${baseListStyling}
  padding-left: ${({ theme }) => theme.space.m};
  list-style: decimal;

  li {
  }

  ${({ alphaLegend, numericLegend }) =>
    (alphaLegend || numericLegend) && LegendStyle};
  ${({ alphaLegend }) => alphaLegend && AlphaLegendStyles};
  ${({ numericLegend }) => numericLegend && NumericLegendStyles};
  ${({ steps }) => steps && StepsStyle};

  ul {
    li {
      list-style: disc;
    }
  }
`;

type ListProps = {
  node: Elvita.Topic;
  children: React.ReactNode;
};

const List: FC<ListProps> = ({ node, children }) => {
  const { name, attribs } = node;
  switch (name) {
    case 'ul':
      return <Ul className={attribs.class}>{children}</Ul>;

    case 'ol': {
      return (
        <Ol
          className={attribs.class}
          alphaLegend={attribs.class === 'alpha-legend'}
          numericLegend={attribs.class === 'numeric-legend'}
          steps={attribs.class === 'steps'}
        >
          {children}
        </Ol>
      );
    }

    default:
      return <ul>{children}</ul>;
  }
};

export default List;
