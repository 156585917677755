import * as React from 'react';
import Modal from './Modal';
import H2 from '../typography/H2';
import styled from 'styled-components';
import Button from '../Button';
import { useTranslation } from '../../hooks/useTranslation';

const Wrapper = styled.div`
  padding: ${({ theme }) => theme.space.l} 0;
  display: flex;
  flex-direction: column;
`;
const StyledH2 = styled(H2)`
  margin-bottom: ${({ theme }) => theme.space.md};
`;

const Space = styled.div`
  margin-bottom: ${({ theme }) => theme.space.s};
`;

type FeedbackModalProps = {
  isVisible: boolean;
  closeHandler: (feedbackProvided?: boolean) => void;
};

const FeedbackModal: React.FC<FeedbackModalProps> = ({
  isVisible,
  closeHandler,
}) => {
  const { t } = useTranslation();

  return (
    <Modal isVisible={isVisible} onClose={() => closeHandler(false)}>
      <Wrapper>
        <StyledH2>{t('feedback.modalTitle') ?? 'feedback.modalTitle'}</StyledH2>

        <Button
          text={t('feedback.optionA') ?? 'feedback.optionA'}
          onClick={() => {
            closeHandler(true);
          }}
          className="feedback-negative-option-a"
        />
        <Space />

        <Button
          text={t('feedback.optionB') ?? 'feedback.optionB'}
          onClick={() => {
            closeHandler(true);
          }}
          className="feedback-negative-option-b"
        />
        <Space />
        <Button
          text={t('feedback.optionC') ?? 'feedback.optionC'}
          onClick={() => {
            closeHandler(true);
          }}
          className="feedback-negative-option-c"
        />
      </Wrapper>
    </Modal>
  );
};

export default FeedbackModal;
