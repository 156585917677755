import React, { FC } from 'react';
import { Redirect, useLocation } from 'react-router';
import styled from 'styled-components';
import Footer from '../Footer';
import Header from '../Header';
import { useLanguageStore } from 'src/context/LanguageStore';
import { useProductConfig } from 'src/hooks/useFetchData';

const Drawer = styled.div`
  display: flex;
  justify-content: flex-end;
  width: ${({ theme }) => theme.sizes.containerWide};
  max-width: 100%;
  align-self: center;
`;

const Wrapper = styled.div`
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  width: 100%;
  @media (min-width: ${({ theme }) => theme.sizes.containerWide}) {
    padding-left: calc(100vw - 100%);
  }
  background-color: ${({ theme }) => theme.colors.elvitaGrayBg};
`;

const Content = styled.div`
  flex: 1;
  justify-content: flex-start;
  flex-direction: column;
  display: flex;

  @media (min-width: ${({ theme }) => theme.screens.l}) {
    justify-content: flex-start;
  }
`;

type PageProps = {
  hideSearch?: boolean;
  hideMenu?: boolean;
  isWizard?: boolean;
  status?: number;
};

const Page: FC<PageProps> = props => {
  const setLang = useLanguageStore(state => state.setLang);

  const { data: productConfig, isError } = useProductConfig();
  if (productConfig) {
    setLang(productConfig.ProductId.slice(-5));
  }

  if (props.status && props.status === 404) {
    return <Redirect to="/404" />;
  }

  return (
    <>
      <Wrapper>
        <Header
          hideSearch={props.hideSearch}
          hideMenu={props.hideMenu}
          isWizard={props.isWizard}
        />
        <Content>{props.children}</Content>
        <Footer />
        <Drawer id="drawer" />
      </Wrapper>
    </>
  );
};

export default Page;
