import { useInternalLink } from '../hooks/useInternalLink';
import { useTopLevelFromMapfile } from './search';
import { getCurrentBrowseItem } from './node';

export function useSearchLink(item: Elvita.SearchResultDocument): {
  link: string;
  title: string | undefined;
} {
  const { internalLink } = useInternalLink();
  const topLevelData = useTopLevelFromMapfile(item.mapfile);
  const isBrowseItem = item.topicType === 'sub-category';

  return {
    link: isBrowseItem
      ? internalLink(
          `/${topLevelData ? `browse/${topLevelData?.topicId}/` : 'browse/'}${
            item.topicId
          }`,
        )
      : internalLink(
          `/${topLevelData ? `${topLevelData?.topicId}/topics/` : 'topics/'}${
            item.topicId
          }`,
        ),
    title: topLevelData?.title ?? undefined,
  };
}

export function useLinkFromId(data: Elvita.Node[], id: string) {
  const { internalLink } = useInternalLink();
  const currentItem = getCurrentBrowseItem(data, id);
  let link: string;

  if (
    currentItem?.currentBrowseItem &&
    currentItem?.currentBrowseItem?.children
  ) {
    link = `/browse/${currentItem?.topLevelItem.topicId}/${id}`;
  } else {
    if (
      id.slice(0, 1) === 'S' &&
      !currentItem?.currentBrowseItem &&
      !currentItem?.topLevelItem
    ) {
      link = `/browse/${id}`;
    } else {
      link = `/${
        currentItem?.topLevelItem
          ? `${currentItem.topLevelItem.topicId}/topics`
          : 'topics'
      }/${id}`;
    }
  }

  return internalLink(link);
}

export function isActive(topicId: string, pathname: string): boolean {
  return pathname.split('/').length > 2
    ? pathname.split('/').includes(topicId)
    : false;
}
