import * as React from 'react';
import styled, { css } from 'styled-components';
import H3 from './typography/H3';
import { useTranslation } from '../hooks/useTranslation';
import { isWizard, isProduct } from '../helpers/wizard';
import { Link, useParams } from 'react-router-dom';
import { useLanguageStore } from 'src/context/LanguageStore';
import consts from 'src/config/consts';

type ListStyle = 'column' | 'row';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const Header = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: ${({ theme }) => `${theme.space.s} 0`};
`;

const ProductsWrapper = styled.div<{ styleType: ListStyle }>`
  display: flex;
  flex-direction: ${({ styleType }) => styleType};
  flex-wrap: ${({ styleType }) => (styleType === 'column' ? 'nowrap' : 'wrap')};
`;

const RowProductWrapperStyle = css`
  flex: 0 0 32%;
  border-width: 1px;
  border-color: ${({ theme }) => theme.colors.elvitaLighterGray};
  margin: 0 0 2% 0;

  @media (max-width: ${({ theme }) => theme.screens.l}) {
    &:nth-child(3n + 2) {
      margin: 0 2% 2%;
    }
  }

  @media (min-width: ${({ theme }) => theme.screens.l}) {
    flex: 0 0 23.5%;
    margin: 0 0 2% 2%;

    &:nth-child(4n + 1) {
      margin-left: 0;
    }
  }
`;

const ProductWrapper = styled.div<{ styleType: ListStyle }>`
  border-top-width: 1px;
  border-top-color: ${({ theme }) => theme.colors.elvitaLighterGray};

  ${({ styleType }) => styleType === 'row' && RowProductWrapperStyle};
`;

const RowProductStyle = css`
  height: 100%;
  flex-direction: column;
  justify-content: center;
`;

const Product = styled(Link)<{ $styleType: ListStyle; $noHref: boolean }>`
  display: flex;
  align-items: center;
  padding: ${({ theme }) => `6px ${theme.space.xs}`};
  cursor: ${({ $noHref }) => ($noHref ? 'default' : 'pointer')};
  pointer-events: ${({ $noHref }) => $noHref && 'none'};

  ${({ $styleType }) => $styleType === 'row' && RowProductStyle};
`;

const StyledH3 = styled(H3)<{ styleType: ListStyle }>`
  font-size: 16px;
  line-height: 19.2px;
  letter-spacing: 0.05em;
  color: ${({ theme }) => theme.colors.elvitaWebPurple};
  text-align: ${({ styleType }) => styleType === 'row' && 'center'};

  @media (hover: hover) {
    ${Product}:hover & {
      color: ${({ theme }) => theme.colors.elvitaWebPink};
    }
  }
`;

const Image = styled.img<{ styleType: ListStyle }>`
  width: 55px;
  margin: ${({ theme, styleType }) =>
    styleType === 'column'
      ? `0 ${theme.space.m} 0 0`
      : `0 0 ${theme.space.s} 0`};
`;

const Title = styled.h4`
  font-size: 12px;
  line-height: 14px;
  color: ${({ theme }) => theme.colors.elvitaGray};
  margin-bottom: ${({ theme }) => theme.space.xxs};

  @media (min-width: ${({ theme }) => theme.screens.sm}) {
    font-size: 16px;
    line-height: 18px;
  }
`;

const IconWrapper = styled.div`
  margin-left: ${({ theme }) => theme.space.xs};
  width: 34px;
`;

const Icon = styled.img`
  cursor: pointer;
`;

const Underline = styled.div<{ show: boolean }>`
  height: 2px;
  border-radius: 2px;
  background-color: ${({ theme }) => theme.colors.elvitaWebPurple};
  margin: ${({ theme }) => `${theme.space.xxxs} 1px 0 1px`};
  display: ${({ show }) => (show ? 'block' : 'none')};

  ${IconWrapper}:hover & {
    background-color: ${({ theme }) => theme.colors.elvitaWebPink};
  }
`;

type ProductListProps = {
  items: Elvita.ProductSearchDocument[] | Elvita.WizardItem[];
  title?: string;
};

const ProductList: React.FC<ProductListProps> = ({ items, title }) => {
  const { t } = useTranslation();
  const [style, setStyle] = React.useState<ListStyle>('column');

  React.useEffect(() => {
    if (window.innerWidth > 640) {
      setStyle('row');
    }
  }, []);

  const toggleListStyle = React.useCallback((style: ListStyle) => {
    setStyle(style);
  }, []);

  if (!items || items.length < 1) {
    return null;
  }

  return (
    <Wrapper>
      <Header>
        <ListIcon listStyle={style} setStyle={toggleListStyle} icon="row" />
        <ListIcon listStyle={style} setStyle={toggleListStyle} icon="column" />
      </Header>
      <Title>
        {title && title.length > 0
          ? title
          : t('search.product') ?? 'search.product'}
      </Title>
      <ProductsWrapper styleType={style}>
        {items.map((item, index) => (
          <Item key={index} item={item} styleType={style} />
        ))}
      </ProductsWrapper>
    </Wrapper>
  );
};

type ItemProps = {
  item: Elvita.WizardItem | Elvita.ProductSearchDocument | Elvita.Product;
  styleType: ListStyle;
};

const Item: React.FC<ItemProps> = ({ item, styleType }) => {
  const lang = useLanguageStore(state => state.lang);

  const { path } = useParams<{ path: string }>();
  const [itemObject, setItemObject] = React.useState<
    { id: string; title?: string; imageId: string } | undefined
  >(undefined);

  React.useEffect(() => {
    if (isWizard(item)) {
      setItemObject({
        id: item.id,
        imageId: item.imageId,
        title: item.title,
      });
    } else if (isProduct(item)) {
      setItemObject({
        id: item.validity.toString(),
        title: item.title,
        imageId: item.imageId,
      });
    } else {
      setItemObject({
        id: item.objectId,
        title: item.description,
        imageId: item.imageId,
      });
    }
  }, [item]);

  const buildHref = (): string => {
    let url: string;
    if (isProduct(item)) {
      url = `/${item.productId}_${lang}`;
    } else if (path) {
      url = `/wizard/${path}/${itemObject?.id}`;
    } else {
      url = `/${itemObject?.id}`;
    }

    return url;
  };

  return (
    <ProductWrapper styleType={styleType}>
      <Product
        $styleType={styleType}
        to={buildHref()}
        $noHref={isWizard(item) && !item?.children}
      >
        <Image
          src={`${consts.storageUrl}images/${itemObject?.imageId}`}
          styleType={styleType}
        />
        <StyledH3 styleType={styleType}>
          {itemObject?.title ? itemObject?.title : 'Missing title'}
        </StyledH3>
      </Product>
    </ProductWrapper>
  );
};

type ListIconProps = {
  icon: ListStyle;
  listStyle: ListStyle;
  setStyle(style: ListStyle): void;
};

const ListIcon: React.FC<ListIconProps> = ({ icon, listStyle, setStyle }) => {
  const [activeIcon, setActiveIcon] = React.useState(false);

  return (
    <IconWrapper
      onMouseEnter={() => setActiveIcon(true)}
      onMouseLeave={() => setActiveIcon(false)}
    >
      <Icon
        src={`/images/${icon}${activeIcon ? '-active' : ''}.svg`}
        onClick={() => setStyle(icon)}
      />
      <Underline show={listStyle === icon} />
    </IconWrapper>
  );
};

export default ProductList;
