import * as React from 'react';
import H1 from '../components/typography/H1';
import H2 from '../components/typography/H2';
import Page from '../components/layout/Page';
import PageContentWrapper from '../components/layout/PageContentWrapper';
import { MainContent } from '../components/layout/MainContent';
import styled from 'styled-components';
import { useTranslation } from '../hooks/useTranslation';
import { useMetaTitle } from 'src/hooks/useMetaTitle';
import { useMatomo } from '@datapunt/matomo-tracker-react';

const StyledH1 = styled(H1)`
  margin: ${({ theme }) => `${theme.space.l} 0 ${theme.space.s} 0`};
  text-align: center;
`;

const StyledH2 = styled(H2)`
  text-align: center;
`;

const NotFound: React.FC = () => {
	const { trackPageView } = useMatomo();
  const { t } = useTranslation();
  useMetaTitle();

  React.useEffect(() => {
	trackPageView(null);
  }, []);

  return (
    <Page isWizard={true} hideMenu={true} hideSearch={true}>
      <PageContentWrapper flex={1}>
        <MainContent>
          <StyledH1>404</StyledH1>
          <StyledH2> {t('notFount.title') ?? 'Page not found'} :(</StyledH2>
        </MainContent>
      </PageContentWrapper>
    </Page>
  );
};

export default NotFound;
