import React from 'react';
import Page from '../components/layout/Page';
import PageContentWrapper from '../components/layout/PageContentWrapper';
import H1 from 'src/components/typography/H1';
import styled from 'styled-components';
import { useParams } from 'react-router-dom';
import { MainContent } from '../components/layout/MainContent';
import Hotspot from '../components/hotspot/Hotspot';
import RelatedContent from '../components/RelatedContent';
import { useHotspot } from '../hooks/useHotspot';
import Breadcrumbs from 'src/components/Breadcrumbs';
import { useTranslation } from '../hooks/useTranslation';
import { useMetaTitle } from 'src/hooks/useMetaTitle';
import { useMatomo } from '@datapunt/matomo-tracker-react';

const StyledH1 = styled(H1)`
  margin: ${({ theme }) =>
    `0 ${theme.space.s} ${theme.space.xl} ${theme.space.s}`};

  @media (min-width: ${({ theme }) => theme.screens.l}) {
    margin: ${({ theme }) => `0 0 ${theme.space.xl} 0`};
  }
`;

const BreadcrumbsBackground = styled.div`
  display: flex;
  flex-direction: column;
  background-color: rgba(242, 242, 242, 0.76);
  margin: ${({ theme }) => `0 -${theme.space.s}`};

  @media (min-width: ${({ theme }) => theme.screens.l}) {
    margin: 0;
  }
`;

const BackgroundGradient = styled.div`
  display: flex;
  flex-direction: column;
  background: linear-gradient(
    180deg,
    #f2f2f2 0%,
    #dee2e6 76.04%,
    rgba(242, 242, 242, 0) 100%
  );
  margin: ${({ theme }) => `0 -${theme.space.s}`};

  @media (min-width: ${({ theme }) => theme.screens.l}) {
    margin: 0;
  }
`;

const BreadcrumbsWrapper = styled(MainContent)`
  padding: ${({ theme }) => `0 ${theme.space.s}`};

  @media (min-width: ${({ theme }) => theme.screens.l}) {
    padding: 0;
  }
`;

const RelatedWrapper = styled.div`
  order: 2;
  margin: ${({ theme }) => `0 -${theme.space.s}`};

  @media (min-width: ${({ theme }) => theme.screens.l}) {
    width: 26.53%;
    order: 1;
    margin: 0;
  }
`;

const StyledRelatedContent = styled(MainContent)`
  @media (min-width: ${({ theme }) => theme.screens.l}) {
    order: 2;
  }
`;

const Hotspots: React.FC = () => {
	const { trackPageView } = useMatomo();
  const { hotspotId } = useParams<{ hotspotId: string }>();
  const { t } = useTranslation();
  const relations: Elvita.Node[] = [];
  const hotspot = useHotspot(hotspotId);
  useMetaTitle();
  
  React.useEffect(() => {
	trackPageView(null);
  }, [hotspotId]);

  if (!hotspot) {
    return null;
  }


  return (
    <Page>
      <PageContentWrapper>
        <BreadcrumbsBackground>
          <BreadcrumbsWrapper>
            <Breadcrumbs
              breadcrumbs={[
                { label: t('hotspot.breadcrumbLabel') ?? 'Overview', url: '' },
              ]}
            />
          </BreadcrumbsWrapper>
        </BreadcrumbsBackground>
      </PageContentWrapper>
      <PageContentWrapper
        flex={1}
        withRelatedContent={relations.length > 0}
        spaceBetween={true}
      >
        <BackgroundGradient>
          <RelatedWrapper id="related-hotspot" />
          <StyledRelatedContent>
            <StyledH1>{hotspot.title}</StyledH1>
            <Hotspot hotspot={hotspot} />
            {relations.length > 0 && (
              <RelatedContent
                portalId="#related-hotspot"
                jsonRelations={relations}
              />
            )}
          </StyledRelatedContent>
        </BackgroundGradient>
      </PageContentWrapper>
    </Page>
  );
};

export default Hotspots;
