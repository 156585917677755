import styled from 'styled-components';

export default styled.h3`
  font-family: ${({ theme }) => theme.fonts.bold};
  font-style: normal;
  /* font-weight: 700; */
  font-size: ${({ theme }) => theme.fontSizes.m};
  line-height: 19.2px;
  color: ${({ theme }) => theme.colors.elvitaTrueBlack};
`;
