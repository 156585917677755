import { useLanguageStore } from 'src/context/LanguageStore';
import * as React from 'react';
import { useQuery } from 'react-query';
import axios from 'axios';
import consts from 'src/config/consts';

export function useTranslation() {
  const lang = useLanguageStore(state => state.lang);
  const url = `/configs/strings_${lang}.json`;

  const { data } = useQuery<{ key: string } | Record<string, never>>(
    ['strings', lang],
    () =>
      axios({
        method: 'get',
        url: url,
        baseURL: consts.storageUrl,
      }).then(response => {
        const returnData = {};
        Object.keys(response.data).map(key => {
          returnData[key.toLocaleLowerCase()] = response.data[key];
        });

        return returnData;
      }),
    { enabled: !!lang },
  );

  const t = React.useCallback(
    (key: string) => {
      if (data) {
        if (!data[key.toLocaleLowerCase()]) {
          return key;
        }
        return data[key.toLocaleLowerCase()];
      }
    },
    [data],
  );
  return { t };
}
