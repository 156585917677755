import * as React from 'react';
import styled from 'styled-components';
import { SwiperSlide } from 'swiper/react';
import Carousel from '../Carousel';
import Img from './Img';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 -14px;

  .swiper-slide {
    height: 90%;
  }

  .swiper-slide-next,
  .swiper-slide-prev {
    position: relative;

    img {
      height: 100%;
    }
  }

  .swiper-slide-next {
    img {
      position: absolute;
      left: -30px;

      @media (min-width: ${({ theme }) => theme.screens.sm}) {
        left: -55px;
      }
    }
  }

  .swiper-slide-prev {
    img {
      position: absolute;
      right: -30px;

      @media (min-width: ${({ theme }) => theme.screens.sm}) {
        right: -55px;
      }
    }
  }

  @media (min-width: ${({ theme }) => theme.screens.md}) {
    margin: unset;
  }
`;

type MedialistProps = {
  items: Elvita.MedialistCarouselData[];
  id: string;
};

const Medialist: React.FC<MedialistProps> = ({ items, id }) => {
  return (
    <Wrapper>
      <Carousel itemsLength={items.length} className={id}>
        {items.map((item, index) => (
          <SwiperSlide
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
            }}
            key={index}
          >
            <Img imageId={item.id} node={item.node} isCarousel />
          </SwiperSlide>
        ))}
      </Carousel>
    </Wrapper>
  );
};

export default Medialist;
