import React from 'react';
import './App.css';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import theme from './config/theme';
import Topic from './pages/Topic';
import Startpage from './pages/Startpage';
import 'swiper/swiper-bundle.min.css';
import Hotspots from './pages/Hotspots';
import Home from './pages/Home';
import Browse from './pages/Browse';
import Search from './pages/Search';
import Wizard from './pages/Wizard';
import NotFound from './pages/NotFound';
import { useMatomo } from '@datapunt/matomo-tracker-react'

function App() {
	const { enableLinkTracking } = useMatomo();

	enableLinkTracking(); 
  return (
    <ThemeProvider theme={theme}>
      <Router>
        <Switch>
          <Route exact path={'/'}>
            <Startpage />
          </Route>
          <Route exact path={'/404'}>
            <NotFound />
          </Route>
          <Route exact path={['/wizard/:path', '/wizard/:path/:childId']}>
            <Wizard />
          </Route>
          <Route exact path="/:productId/hotspots/:hotspotId">
            <Hotspots />
          </Route>
          <Route
            path={[
              '/:productId/topics/:topicId',
              '/:productId/:path/topics/:topicId',
            ]}
          >
            <Topic />
          </Route>
          <Route
            exact
            path={[
              '/:productId/browse/',
              '/:productId/browse/:path',
              '/:productId/browse/:path/:childId',
            ]}
          >
            <Browse />
          </Route>
          <Route
            exact
            path={[
              '/search/:searchType',
              '/search/:searchType/:searchTerm',
              '/:productId/search/:searchType',
              '/:productId/search/:searchType/:searchTerm',
            ]}
          >
            <Search />
          </Route>
          <Route exact path={'/:productId'}>
            <Home />
          </Route>
          <NotFound />
        </Switch>
      </Router>
      <div id="modal"></div>
    </ThemeProvider>
  );
}

export default App;
