import * as React from 'react';
import { useLinkFromId } from 'src/helpers/link';
import { useFetchBrowse } from 'src/hooks/useFetchData';
import { useNodes } from '../../hooks/useNodes';
import { useTranslation } from 'src/hooks/useTranslation';
import styled, { keyframes } from 'styled-components';
import H3 from '../typography/H3';
import { getCurrentBrowseItem } from '../../helpers/node';
import Breadcrumbs from '../../components/Breadcrumbs';
import { Link } from 'react-router-dom';

const show = keyframes`
  0% { opacity: 0; }
  100% { opacity: 1; }
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: ${({ theme }) => theme.space.xl};
  animation: ${show} 0.8s;
`;

const ResultsCount = styled.div`
  font-size: ${({ theme }) => theme.fontSizes.xxxs};
  line-height: 12px;
  letter-spacing: 0.05em;
  color: ${({ theme }) => theme.colors.elvitaMagenta};
  text-align: center;
  margin-bottom: ${({ theme }) => theme.space.xxs};
`;

type SearchResultsTopicProps = {
  items: Elvita.SearchResultDocument[];
};

const SearchResultsTopic: React.FC<SearchResultsTopicProps> = ({ items }) => {
  const { t } = useTranslation();
  const { error, data } = useFetchBrowse();

  if (items.length < 1 || !data || error) {
    return null;
  }

  return (
    <Wrapper>
      {}
      <ResultsCount>
        {items.length} {t('search.resultsCount') ?? 'results'}
      </ResultsCount>
      {items.map((item, index) => (
        <Item item={item} key={index} data={data?.data} />
      ))}
    </Wrapper>
  );
};

const ItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: ${({ theme }) => theme.space.xs};
  border-bottom-width: 1px;
  border-bottom-color: ${({ theme }) => theme.colors.elvitaMidGray};
  padding-bottom: ${({ theme }) => theme.space.xs};
`;

const StyledH3 = styled(H3)`
  font-size: ${({ theme }) => theme.fontSizes.s};
  line-height: 16.8px;
  letter-spacing: 0.05em;
  color: ${({ theme }) => theme.colors.elvitaWebPurple};
  margin-bottom: ${({ theme }) => theme.space.xs};

  ${ItemWrapper}:hover & {
    color: ${({ theme }) => theme.colors.elvitaWebPink};
  }
`;

const Summary = styled.div`
  font-size: ${({ theme }) => theme.fontSizes.xxxs};
  line-height: 12px;
  letter-spacing: 0.05em;
  color: ${({ theme }) => theme.colors.elvitaGray};
`;

type ItemProps = {
  item: Elvita.SearchResultDocument;
  data: Elvita.Node[];
};

const Item: React.FC<ItemProps> = ({ item, data }) => {
  const browseItem = getCurrentBrowseItem(data, item.topicId);

  const { breadcrumbs } = useNodes(
    browseItem.topLevelItem ?? data[0],
    item.topicId,
    browseItem?.topLevelItem?.topicId ?? item.topicId,
    browseItem?.topLevelItem?.title ?? '',
  );

  return (
    <Link
      to={useLinkFromId(data, item.topicId)}
      style={{
        cursor: 'pointer',
      }}
    >
      <ItemWrapper>
        <Breadcrumbs
          breadcrumbs={breadcrumbs.slice(0, -1)}
          inSearchResult={true}
        />
        <StyledH3>{item.title}</StyledH3>
        <Summary>{item.summary}</Summary>
      </ItemWrapper>
    </Link>
  );
};

export default SearchResultsTopic;
