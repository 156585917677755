import * as React from 'react';
import Page from '../components/layout/Page';
import PageContentWrapper from '../components/layout/PageContentWrapper';
import { MainContent } from '../components/layout/MainContent';
import styled from 'styled-components';
import { useFetchBrowse, useFetchStartPage } from '../hooks/useFetchData';
import SearchBox from '../components/search/SearchBox';
import { useLink } from '../helpers/menu';
import { useMetaTitle } from 'src/hooks/useMetaTitle';
import { Link } from 'react-router-dom';
import TopicHotspot from 'src/components/topic/TopicHotspot';
import { useMatomo } from '@datapunt/matomo-tracker-react';

const Outer = styled.div`
  background-color: ${({ theme }) => theme.colors.elvitaLighterGray};
  padding: ${({ theme }) => `${theme.space.s} 0px 30px 0px`};
  display: flex;
  justify-content: center;
`;

const LinksWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-self: center;
  width: 80%;
  padding: ${({ theme }) => `${theme.space.xxl} 0`};

  @media (min-width: ${({ theme }) => theme.screens.md}) {
    width: 63%;
  }
`;

const HotspotWrapper = styled.div`
  display: 'flex';
  justify-content: center;
  margin-bottom: 35px;
  padding: 14px;
  overflow: hidden;
`;

const StyledMainContent = styled(MainContent)`
  display: flex;
  flex-direction: column;
`;

const Home: React.FC = () => {
	const { trackPageView } = useMatomo();
  const { data, isError, isLoading } = useFetchStartPage();
  useMetaTitle();

  React.useEffect(() => {
	trackPageView(null);
  }, []);

  if (isLoading) {
    return null;
  }

  return (
    <Page status={isError ? 404 : data.status}>
      {data?.status === 200 && (
        <PageContentWrapper flex={1} noPadding>
          <Outer>
            <StyledMainContent>
              <HotspotWrapper>
                {data?.data?.featuredArticles.map((article, index) =>
                  article?.icon === 'overview' || article?.icon === '' ? (
                    <TopicHotspot hotspotId={article?.target} key={index} />
                  ) : null,
                )}
              </HotspotWrapper>

              <SearchBox searchType="content" />
            </StyledMainContent>
          </Outer>
          {data?.data?.featuredArticles.length > 0 && (
            <LinksWrapper>
              {data?.data.featuredArticles.map((article, index) =>
                article?.icon === 'overview' || article?.icon === '' ? null : (
                  <FeaturedItem item={article} key={index} />
                ),
              )}
            </LinksWrapper>
          )}
        </PageContentWrapper>
      )}
    </Page>
  );
};

const FeaturedItemWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  flex: 0 0 50%;
  margin-bottom: ${({ theme }) => theme.space.l};

  @media (min-width: ${({ theme }) => theme.screens.md}) {
    flex: 0 0 33.33%;
  }
`;

const Circle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 62px;
  height: 62px;
  border-radius: 62px;
  background: linear-gradient(180deg, #390065 0%, #291737 100%);
  margin-bottom: ${({ theme }) => theme.space.xs};
`;

const Label = styled.div`
  font-family: ${({ theme }) => theme.fonts.regular};
  font-size: ${({ theme }) => theme.fontSizes.s};
  line-height: 16.8px;
  letter-spacing: 0.02em;
  color: ${({ theme }) => theme.colors.elvitaWebPurple};
  text-align: center;
`;

const StyledLink = styled(Link)`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const A = styled.a`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const FeaturedItem: React.FC<{ item: Elvita.HomePageLink }> = ({ item }) => {
  const { data } = useFetchBrowse();

  const theLink = useLink(item, data?.data[0].topicId, data?.data);

  return (
    <FeaturedItemWrapper>
      {item.type === 'file' ? (
        <A href={theLink} target="_blank">
          <Circle>
            <img src={`/images/${item.icon}.svg`} />
          </Circle>
          <Label>{item.title}</Label>
        </A>
      ) : (
        <StyledLink to={theLink}>
          <Circle>
            <img src={`/images/${item.icon}.svg`} />
          </Circle>
          <Label>{item.title}</Label>
        </StyledLink>
      )}
    </FeaturedItemWrapper>
  );
};

export default Home;
