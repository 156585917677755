import { useLanguageStore } from 'src/context/LanguageStore';
import { useQuery, UseBaseQueryResult, useQueryClient } from 'react-query';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import consts from 'src/config/consts';

type HomepageResponse = {
  data: Elvita.HomePageData;
  status: number;
  lastModified: string;
};

export function useFetchStartPage() {
  const { data: productConfig, isLoading, isError } = useProductConfig();
  const manifestId = productConfig?.ManifestId;

  const query = useQuery<HomepageResponse>(
    ['startpage', manifestId],
    () =>
      axios({
        method: 'get',
        url: `/manifests/${manifestId}/startpage.json`,
        baseURL: consts.storageUrl,
      })
        .then(response => {
          return {
            data: response.data,
            status: response.status,
            lastModified: response.headers['last-modified'],
          };
        })
        .catch(error => error.response),
    { enabled: !!manifestId },
  );

  return {
    ...query,
    isError: isError || query.isError,
    isLoading: isLoading || query.isLoading,
  };
}

export function useFetchSettings() {
  const { data: productConfig } = useProductConfig();
  const manifestId = productConfig?.ManifestId;

  return useQuery(
    ['settings', manifestId],
    () =>
      axios({
        method: 'get',
        url: `/manifests/${manifestId}/settings.json`,
        baseURL: consts.storageUrl,
      })
        .then(response => response.data)
        .catch(error => error.response),
    { enabled: !!manifestId },
  );
}

type BrowseResponse = { data: Elvita.Node[]; status: number };

export function useFetchBrowse(): UseBaseQueryResult<BrowseResponse> {
  const { data: productConfig } = useProductConfig();
  const manifestId = productConfig?.ManifestId;

  return useQuery<BrowseResponse>(
    ['browse', manifestId],
    () =>
      axios({
        method: 'get',
        url: `/manifests/${manifestId}/browse.json`,
        baseURL: consts.storageUrl,
      })
        .then(response => {
          return { data: response.data, status: response.status };
        })
        .catch(error => error.response),
    { enabled: !!manifestId },
  );
}

type TopicResponse = { data: string; status: number };

export function useFetchTopic(
  topicId: string,
): UseBaseQueryResult<TopicResponse> {
  const { data: productConfig } = useProductConfig();
  const manifestId = productConfig?.ManifestId;

  return useQuery<TopicResponse>(
    ['topic', manifestId, topicId],
    () =>
      axios({
        method: 'get',
        url: `/manifests/${manifestId}/html/${topicId}.html`,
        baseURL: consts.storageUrl,
      })
        .then(response => {
          return { data: response.data, status: response.status };
        })
        .catch(error => error.response),
    { enabled: !!manifestId && !!topicId },
  );
}

export function useFetchHotspots(): UseBaseQueryResult<Elvita.Hotspot[]> {
  const { data: productConfig } = useProductConfig();
  const manifestId = productConfig?.ManifestId;

  return useQuery<Elvita.Hotspot[]>(
    ['hotspots', manifestId],
    () =>
      axios({
        method: 'get',
        url: `/manifests/${manifestId}/hotspots.json`,
        baseURL: consts.storageUrl,
      })
        .then(response => response.data)
        .catch(error => error.response),
    { enabled: !!manifestId },
  );
}

type WizardStructureResponse = { data: Elvita.WizardItem[]; status: number };

export function useFetchWizardStructure(): UseBaseQueryResult<WizardStructureResponse> {
  const lang = useLanguageStore(state => state.lang);

  return useQuery<WizardStructureResponse>(
    ['wizard', lang],
    () =>
      axios({
        method: 'get',
        url: `/configs/wizard_${lang}.json`,
        baseURL: consts.storageUrl,
      })
        .then(response => {
          return { data: response.data, status: response.status };
        })
        .catch(error => error.response),
    { enabled: !!lang },
  );
}

export function useFetchMenu(): UseBaseQueryResult<Elvita.MenuItem[]> {
  const { data: productConfig } = useProductConfig();
  const manifestId = productConfig?.ManifestId;

  return useQuery<Elvita.MenuItem[]>(
    ['main-menu', manifestId],
    () =>
      axios({
        method: 'get',
        url: `/manifests/${manifestId}/menu.json`,
        baseURL: consts.storageUrl,
      })
        .then(response => response.data)
        .catch(error => error.response),
    { enabled: !!manifestId },
  );
}

const getProductConfigs = async () => {
  const response = await axios({
    method: 'get',
    url: `/configs/productconfigs.json`,
    baseURL: consts.storageUrl,
  });
  return response.data as Elvita.ProductConfigItem[];
};

export function useProductConfigs(): UseBaseQueryResult<
  Elvita.ProductConfigItem[]
> {
  return useQuery<Elvita.ProductConfigItem[]>(['product-configs'], () =>
    getProductConfigs(),
  );
}

export function useProductConfig(): UseBaseQueryResult<Elvita.ProductConfigItem> {
  const { productId } = useParams<{ productId: string }>();
  const queryClient = useQueryClient();

  return useQuery<Elvita.ProductConfigItem>(
    ['product-configs', productId],
    async () => {
      const res = await getProductConfigs();
      queryClient.setQueryData('product-configs', res);
      const productConfig = res.find(item => item.ProductId === productId);
      if (!productConfig) {
        throw new Error('Product not found');
      }
      return productConfig;
    },
    {
      enabled: !!productId,
      initialData: () =>
        queryClient
          .getQueryData<Elvita.ProductConfigItem[]>('product-configs')
          ?.find(item => item.ProductId === productId),
    },
  );
}
