export function getItemTitle(
  id: string,
  data: Elvita.WizardItem[],
  parentId: string | undefined,
): string {
  if (data) {
    const topLevelId = parentId ?? id;
    const topLevel = data.find(item => item.id === topLevelId);

    if (!parentId && topLevel) {
      return topLevel?.title;
    }

    return topLevel?.children.find(child => child.id === id)?.title ?? '';
  }

  return '';
}

/* eslint-disable-next-line @typescript-eslint/no-explicit-any */
export function isWizard(item: any): item is Elvita.WizardItem {
  return item.id !== undefined;
}

/* eslint-disable-next-line @typescript-eslint/no-explicit-any */
export function isProduct(item: any): item is Elvita.Product {
  return item.validity !== undefined;
}
