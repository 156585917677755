import styled from 'styled-components';

export default styled.h2`
  font-family: ${({ theme }) => theme.fonts.bold};
  font-style: normal;
  /* font-weight: 700; */
  font-size: ${({ theme }) => theme.fontSizes.ml};
  line-height: ${({ theme }) => theme.lineHeights.s};
  color: ${({ theme }) => theme.colors.elvitaTrueBlack};
`;
