const theme = {
  colors: {
    elvitaDarkBlue: '#023E84',
    elvitaMediumGrey: '#49515B',
    elvitaTrueBlack: '#000',
    elvitaTableBorder: '#A0A0A0',
    elvitaWarningYellow: '#FFD442',
    elvitaBrown: '#5D5F56',
    elvitaGreen: '#47959F',
    elvitaRed: '#E52729',
    elvitaGray: '#707070',
    elvitaMidGray: '#DEE2E6',
    elvitaWhite: '#FFF',
    elvitaBlack: '#000',
    elvitaCautionBlue: '#8AA8D7',
    elvitaLightGray: '#49515B1A',
    elvitaLighterGray: '#F2F2F2',
    elvitaGray20: '#D3D5D8',
    elvitaListBorder: '#A7ACB0',
    elvitaDisabled: '#A1A1A1',
    elvitaWebPurple: '#522D6D',
    elvitaPurpleDark: '#291737',
    elvitaWebPink: '#C74781',
    elvitaWebBeige: '#F7F0E7',
    elvitaMagenta: '#E6007E',
    elvitaGrayBg: '#F1F1F1',

    infoTypes: {
      service: '#78C2DE',
      instruction: '#FBE400',
      parts: '#B9D3B7',
    },
  },

  space: {
    xxxxl: '65px',
    xxxl: '60px',
    xxl: '55px',
    xl: '40px',
    l: '34px',
    lg: '28px',
    md: '24px',
    m: '20px',
    s: '14px',
    sm: '12px',
    xs: '8px',
    xxs: '4px',
    xxxs: '3px',
    gutter: '20px',
    attention: '21px',
    table: '5px',
  },

  fontSizes: {
    xxxl: '48px',
    xxl: '36px',
    xl: '26px',
    l: '24px',
    ml: '20px',
    m: '16px',
    s: '14px',
    xxs: '13px',
    xxxs: '10px',
  },
  lineHeights: {
    l: '44px',
    m: '30px',
    s: '24px',
    xs: '20.7px',
    xsm: '19px',
    xxs: '18px',
    xxxs: '14px',
    xxxxs: '12px'
  },
  fonts: {
    regular: 'VAG-Black',
    bold: 'VAG-Bold',
    light: 'VAG-Light',
  },
  radii: {
    l: '10px',
    m: '6px',
    s: '4px',
    xs: '2px',
  },
  borderWidths: {
    xl: 6.42,
    l: 4,
    m: 2,
    s: 1,
    attention: 5,
  },
  sizes: {
    containerWide: '1280px',
  },
  screens: {
    l: '1025px',
    md: '768px',
    sm: '640px',
  },
};

export type ThemeType = typeof theme;

export default theme;
