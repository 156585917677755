import * as React from 'react';
import { useHotspot } from 'src/hooks/useHotspot';
import Hotspot from '../hotspot/Hotspot';

const TopicHotspot: React.FC<{ hotspotId: string }> = ({ hotspotId }) => {
  const hotspot = useHotspot(hotspotId);

  if (!hotspot) {
    return null;
  }

  return <Hotspot hotspot={hotspot} />;
};

export default TopicHotspot;
